import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";
//-------------------------------
//Create Workout Template
//-------------------------------
export const createWorkoutTemplateAction = createAsyncThunk(
  "workout/created",
  async (workout, { rejectWithValue, getState, dispatch }) => {
    // Get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const formData = new FormData();
      formData.append("overallGoal", workout?.overallGoal);
      formData.append("workoutFocus", workout?.workoutFocus);
      // Handle notes, image link, and YouTube link
      formData.append("notes", workout?.notes || "");
      formData.append("imageLink", workout?.imageLink || "");
      formData.append("youtubeLink", workout?.youtubeLink || "");

      // Handle the warmUp array
      if (Array.isArray(workout?.workouts?.warmUp)) {
        workout.workouts.warmUp.forEach((exercise, index) => {
          formData.append(
            `workouts[warmUp][${index}][exerciseName]`,
            exercise.exerciseName || ""
          );
          formData.append(
            `workouts[warmUp][${index}][sets]`,
            exercise.sets || ""
          );
          formData.append(
            `workouts[warmUp][${index}][reps]`,
            exercise.reps || ""
          );
          formData.append(
            `workouts[warmUp][${index}][tempo]`,
            exercise.tempo || ""
          );
          formData.append(
            `workouts[warmUp][${index}][load]`,
            exercise.load || ""
          );
          formData.append(
            `workouts[warmUp][${index}][weight]`,
            exercise.weight || ""
          );
          formData.append(
            `workouts[warmUp][${index}][rest]`,
            exercise.rest || ""
          );
          formData.append(
            `workouts[warmUp][${index}][notes]`,
            exercise.notes || ""
          );
          formData.append(
            `workouts[warmUp][${index}][imageLink]`,
            exercise.imageLink || ""
          );
          formData.append(
            `workouts[warmUp][${index}][youtubeLink]`,
            exercise.youtubeLink || ""
          );
          // ... continue appending other exercise properties
        });
        workout.workouts.resistance.forEach((exercise, index) => {
          formData.append(
            `workouts[resistance][${index}][exerciseName]`,
            exercise.exerciseName || ""
          );
          formData.append(
            `workouts[resistance][${index}][sets]`,
            exercise.sets || ""
          );
          formData.append(
            `workouts[resistance][${index}][reps]`,
            exercise.reps || ""
          );
          formData.append(
            `workouts[resistance][${index}][tempo]`,
            exercise.tempo || ""
          );
          formData.append(
            `workouts[resistance][${index}][load]`,
            exercise.load || ""
          );
          formData.append(
            `workouts[resistance][${index}][weight]`,
            exercise.weight || ""
          );
          formData.append(
            `workouts[resistance][${index}][rest]`,
            exercise.rest || ""
          );
          formData.append(
            `workouts[resistance][${index}][notes]`,
            exercise.notes || ""
          );
          formData.append(
            `workouts[resistance][${index}][imageLink]`,
            exercise.imageLink || ""
          );
          formData.append(
            `workouts[resistance][${index}][youtubeLink]`,
            exercise.youtubeLink || ""
          );
          // ... continue appending other exercise properties
        });
        workout.workouts.cardio.forEach((exercise, index) => {
          formData.append(
            `workouts[cardio][${index}][exerciseName]`,
            exercise.exerciseName || ""
          );
          formData.append(
            `workouts[cardio][${index}][sets]`,
            exercise.sets || ""
          );
          formData.append(
            `workouts[cardio][${index}][reps]`,
            exercise.reps || ""
          );
          formData.append(
            `workouts[cardio][${index}][tempo]`,
            exercise.tempo || ""
          );
          formData.append(
            `workouts[cardio][${index}][load]`,
            exercise.load || ""
          );
          formData.append(
            `workouts[cardio][${index}][weight]`,
            exercise.weight || ""
          );
          formData.append(
            `workouts[cardio][${index}][rest]`,
            exercise.rest || ""
          );
          formData.append(
            `workouts[cardio][${index}][notes]`,
            exercise.notes || ""
          );
          formData.append(
            `workouts[cardio][${index}][imageLink]`,
            exercise.imageLink || ""
          );
          formData.append(
            `workouts[cardio][${index}][youtubeLink]`,
            exercise.youtubeLink || ""
          );
          // ... continue appending other exercise properties
        });
      }

      const { data } = await axios.post(
        `${baseUrl}/workouttemplate`,
        formData,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Fetch all Workout Template With trainer
//-------------------------------

export const fetchWorkoutsAction = createAsyncThunk(
  "workout/getWorkouts",
  async (_, { rejectWithValue, getState }) => {
    // Get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      // HTTP call
      const { data } = await axios.get(
        `${baseUrl}/workouttemplate/trainer-workout`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Fetch All workouts with Client
//-------------------------------
export const fetchAllWorkoutPlansWithClient = createAsyncThunk(
  "get/workoutwithclient",

  async (workoutId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/workouttemplate/client-workout`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Fetch One workouts with Client
//-------------------------------
export const fetchOneWorkoutPlansWithClient = createAsyncThunk(
  "get/oneworkoutwithclient",

  async (workoutId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/workouttemplate/trainer-workout-temp/${workoutId}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Delete Workout Template for Trainer
//-------------------------------
export const deleteWorkoutTemplateFromTrainerAction = createAsyncThunk(
  "delete/workoutplan",
  async (workoutId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call
      const { data } = await axios.delete(
        `${baseUrl}/workouttemplate/trainer-workout`,
        {
          ...config,
          data: { workoutId: workoutId }, // Pass the guideline ID in the request body
        }
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const workoutSlice = createSlice({
  name: "workout",
  initialState: {},
  extraReducers: (builder) => {
    //-------------------------------
    // create Workout template
    //-------------------------------
    builder.addCase(createWorkoutTemplateAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createWorkoutTemplateAction.fulfilled, (state, action) => {
      state.workoutCreated = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createWorkoutTemplateAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //-------------------------------
    // Fetch all Workout template for the trainer
    //-------------------------------
    builder.addCase(fetchWorkoutsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchWorkoutsAction.fulfilled, (state, action) => {
      state.fetchWorkout = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchWorkoutsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //-------------------------------
    // Fetch all Workout template for the Client
    //-------------------------------
    builder.addCase(fetchAllWorkoutPlansWithClient.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      fetchAllWorkoutPlansWithClient.fulfilled,
      (state, action) => {
        state.workoutClient = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchAllWorkoutPlansWithClient.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    //-------------------------------
    // Fetch One Workout template for the Client
    //-------------------------------
    builder.addCase(fetchOneWorkoutPlansWithClient.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      fetchOneWorkoutPlansWithClient.fulfilled,
      (state, action) => {
        state.workoutSelected = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchOneWorkoutPlansWithClient.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    //-------------------------------
    //Delete Workout Template for Trainer
    //-------------------------------
    builder.addCase(
      deleteWorkoutTemplateFromTrainerAction.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      deleteWorkoutTemplateFromTrainerAction.fulfilled,
      (state, action) => {
        state.deleteWorkout = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      deleteWorkoutTemplateFromTrainerAction.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
  },
});
export default workoutSlice.reducer;
