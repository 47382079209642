import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

//supplement functions
export const createSupplementAction = createAsyncThunk(
  "supplement/created",
  async (supplement, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const formData = new FormData();
      formData.append("title", supplement?.title);
      formData.append("description", supplement?.description);
      formData.append("image", supplement?.image);

      //http call

      const { data } = await axios.post(
        `${baseUrl}/supplement`,
        formData,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//fetch all Supplements action
export const fetchSupplementAction = createAsyncThunk(
  "post/getSupplements",

  async (supplementId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/supplement/trainer-supplement`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Fetch All Supplements with Client
//-------------------------------
export const fetchAllSupplementPlansWithClient = createAsyncThunk(
  "get/supplementPlansWithClient",

  async (supplementId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/supplement/client-supplement`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------
//-------------------------------
//Delete Supplement From Trainer
//-------------------------------
export const deleteSupplementTemplateFromTrainerAction = createAsyncThunk(
  "delete/supplement",
  async (supplementId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call
      const { data } = await axios.delete(
        `${baseUrl}/supplement/trainer-supplement`,
        {
          ...config,
          data: { supplementId: supplementId }, // Pass the guideline ID in the request body
        }
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const supplementSlice = createSlice({
  name: "getSupplement",
  initialState: {},
  extraReducers: (builder) => {
    // create post
    builder.addCase(createSupplementAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createSupplementAction.fulfilled, (state, action) => {
      state.supplement = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createSupplementAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    // fetch all posts
    builder.addCase(fetchSupplementAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchSupplementAction.fulfilled, (state, action) => {
      state.supplementCreated = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchSupplementAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //-------------------------------
    // get all guideline for specific Client
    //-------------------------------
    builder.addCase(
      fetchAllSupplementPlansWithClient.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchAllSupplementPlansWithClient.fulfilled,
      (state, action) => {
        state.fetchClientSupplement = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchAllSupplementPlansWithClient.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    builder.addCase(
      deleteSupplementTemplateFromTrainerAction.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      deleteSupplementTemplateFromTrainerAction.fulfilled,
      (state, action) => {
        state.deleteSupplement = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      deleteSupplementTemplateFromTrainerAction.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
  },
});
export default supplementSlice.reducer;
