import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  HeartIcon,
  EmojiSadIcon,
  UploadIcon,
  UserIcon,
} from "@heroicons/react/outline";

import { MailIcon, EyeIcon } from "@heroicons/react/solid";
// import "./TrainerProfile.scss";
import {
  fetchTrainerProfileAction,
  followTrainerAction,
  unfollowTrainerAction,
} from "../../../redux/slices/users/usersSlices";

import { useDispatch, useSelector } from "react-redux";
import DateFormatter from "../../../utils/DateFormatter";
import LoadingComponent from "../../../utils/LoadingComponent";

const CoachDetails = () => {
  const { id } = useParams();

  function capitalizeWord(word) {
    if (typeof word === "string" && word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    // Return an empty string or handle the case when word is undefined or empty
    return ""; // You can return an empty string or any other value as needed
  }
  const dispatch = useDispatch();

  //User data from store
  const users = useSelector((state) => state?.users);
  const {
    trainerDetail,
    followTrainer,
    unFollowTrainer,
    loading,
    appErr,
    ServerErr,
  } = users;

  //Fetch user Profile
  useEffect(() => {
    dispatch(fetchTrainerProfileAction(id));
  }, [id, dispatch]);
  const handleFollow = () => {
    dispatch(followTrainerAction(id));
  };

  const handleUnfollow = () => {
    dispatch(unfollowTrainerAction(id));
  };

  useEffect(() => {
    if (followTrainer || unFollowTrainer) {
      window.location.reload(); // Refresh the page after following or unfollowing
    }
  }, [followTrainer, unFollowTrainer]);

  if (followTrainer || unFollowTrainer) {
    return <Navigate to={`/trainer-profile-details/${trainerDetail?._id}`} />;
  }
  return (
    <>
      <div className="min-h-screen flex justify-center items-center">
        {loading ? (
          <LoadingComponent />
        ) : appErr || ServerErr ? (
          <h2>
            {ServerErr} {appErr}
          </h2>
        ) : (
          <div className=" flex overflow-hidden ">
            {/* h-screen */}
            {/* Static sidebar for desktop */}

            <div className="flex flex-col min-w-0 flex-1 overflow-hidden pt-24">
              <div className="flex-1 relative z-0 flex overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                  <article>
                    {/* Profile header */}
                    <div>
                      {/* <div>
                        <img
                          className="h-32 w-full object-cover lg:h-48"
                          src={trainerDetail?.profilePhoto}
                          alt={trainerDetail?.firstName}
                        />
                      </div> */}
                      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 ">
                        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5 ">
                          <div className="flex mt-20  ">
                            <img
                              className="h-24 w-24 rounded-full  ring-4 ring-white  sm:h-32 sm:w-32"
                              src={trainerDetail?.profilePhoto}
                              alt={trainerDetail?.fullName}
                            />
                          </div>
                          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1 ">
                            <div className=" flex flex-col 2xl:block mt-10 min-w-0 flex-1 ">
                              <h1 className="text-2xl font-bold text-white ">
                                {capitalizeWord(trainerDetail?.firstName)}{" "}
                                {capitalizeWord(trainerDetail?.lastName)}
                                {/* <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-white">
                          {profile?.accountType}
                        </span> */}
                                {/* Display if verified or not */}
                                {trainerDetail?.isAccountVerified ? (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-green-600 text-gray-300">
                                    Account Verified
                                  </span>
                                ) : (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-red-600 text-gray-300">
                                    Unverified Account
                                  </span>
                                )}
                                {trainerDetail?.isTrainer ? (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-green-600 text-gray-300">
                                    Trainer
                                  </span>
                                ) : (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-red-600 text-gray-300">
                                    Client
                                  </span>
                                )}
                                {trainerDetail?.isAdmin ? (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-blue-600 text-gray-300">
                                    Super Admin💪
                                  </span>
                                ) : (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-red-600 text-gray-300">
                                    Normal
                                  </span>
                                )}
                              </h1>
                              <p className="m-3 text-white text-lg">
                                Date Joined:{" "}
                                <DateFormatter
                                  date={trainerDetail?.createdAt}
                                />
                              </p>
                              <p className="m-3 text-black bg-white pl-5 text-xl">
                                {trainerDetail?.bio}
                              </p>
                              <p className="text-green-400 mt-2 mb-2">
                                {trainerDetail?.posts.length} Achievements{" "}
                                {/* {trainerDetail?.followers.length} Clients{" "} */}
                              </p>
                              {/* Who view my profile */}

                              {/* is login user */}
                              {/* Upload profile photo */}
                            </div>

                            <div className="mt-6  flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                              {/* // Hide follow button from the same */}
                              <div>
                                {trainerDetail?.isFollowing ? (
                                  <button
                                    onClick={handleUnfollow}
                                    className="inline-flex justify-center  px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-red-800 hover:bg-gray-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                  >
                                    <EmojiSadIcon
                                      className="-ml-1 mr-2 h-5 w-5 "
                                      aria-hidden="true"
                                    />
                                    <span>Unfollow</span>
                                  </button>
                                ) : (
                                  <button
                                    onClick={handleFollow}
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-green-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                  >
                                    <HeartIcon
                                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span>Follow </span>
                                  </button>
                                )}
                                <></>
                              </div>

                              {/* Update Profile */}

                              <></>
                              {/* Send Mail */}
                              {/* <Link
                                // to={`/send-mail?email=${profile?.email}`}
                                className="inline-flex justify-center bg-indigo-900 px-4 py-2 border border-yellow-700 shadow-sm text-sm font-medium rounded-md text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                              >
                                <MailIcon
                                  className="-ml-1 mr-2 h-5 w-5 text-gray-200"
                                  aria-hidden="true"
                                />
                                <span className="text-base mr-2  text-bold text-yellow-500">
                                  Send Message
                                </span>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center place-items-start flex-wrap  md:mb-0">
                          <h1 className="text-6xl font-bold text-white truncate">
                            {capitalizeWord(trainerDetail?.lastName)}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {/* Tabs */}
                    <div className="mt-6 sm:mt-2 2xl:mt-5">
                      <div className="border-b border-red-900">
                        <div className="max-w-5xl mx-auto "></div>
                      </div>
                    </div>
                    <div className="flex justify-center place-items-start flex-wrap  md:mb-0">
                      {/* <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
                <h1 className="text-center text-xl text-white border-gray-500 mb-2 border-b-2">
                  Who viewed my profile : 9
                </h1>

            
                <ul className="">
                  <Link>
                    <div className="flex mb-2 items-center space-x-4 lg:space-x-6">
                      <img
                        className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                        src={profile.profilePhoto}
                        alt={profile?._id}
                      />
                      <div className="font-medium text-lg text-white leading-6 space-y-1">
                        <h3>
                          {user?.firstName} {user?.lastName}
                        </h3>
                        <p className="text-indigo-600 text-white">
                          {user.accountType} 
                        </p>
                      </div>
                    </div>
                  </Link>
                </ul>
              </div> */}
                      {/* All my Post */}
                      <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0 text-white">
                        <h1 className="text-center text-xl border-gray-500 mb-2 border-b-2">
                          Trainer Achievements
                        </h1>
                        {/* Loo here */}
                        {trainerDetail?.posts?.length <= 0 ? (
                          <h2 className="text-center text-xl">
                            {" "}
                            No Achievements Added Yet
                          </h2>
                        ) : (
                          trainerDetail?.posts?.map((post) => (
                            <div
                              key={post._id}
                              className="flex flex-wrap  -mx-3 mt-3  lg:mb-6"
                            >
                              <div className="mb-2   w-full lg:w-1/4 px-3">
                                <img
                                  className="object-cover h-100 rounded"
                                  src={post?.image}
                                  alt="poster"
                                />
                              </div>
                              <div className="w-full lg:w-3/4 px-3">
                                <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                                  {capitalizeWord(post?.title)}
                                </h3>

                                <p className="text-white ">
                                  {post?.description}
                                </p>
                                <p className="m-3 text-black bg-white font-bold text-lg">
                                  Created At:{" "}
                                  <DateFormatter date={post?.createdAt} />
                                </p>
                                {/* Read more */}
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CoachDetails;
