import React from "react";
import "./WorkoutPage.scss";
import CountDownCounter from "../../components/CountDownCounter/CountDownCounter";
const Workout = () => {
  return (
    <div>
      <div className="app_workout-background">
        <CountDownCounter />
      </div>
    </div>
  );
};

export default Workout;
