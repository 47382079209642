import React, { useEffect } from "react";

import "./SupplementTemplates.scss";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createSupplementAction,
  deleteSupplementTemplateFromTrainerAction,
  fetchSupplementAction,
} from "../../../redux/slices/supplement/supplementSlice";
import styled from "styled-components";
import Dropzone from "react-dropzone";

const formSchema = Yup.object({
  title: Yup.string().required("يرجى إدخال إسم المكمل"),
  description: Yup.string().required("يرجى إدخال وصف المكمل"),
  image: Yup.string().required("يرجى إدخال صورة المكمل"),
});

//css for dropzone
const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
  border-color:'red'
    transition: border 0.24s ease-in-out;
  `;

export default function SupplementTemplates() {
  const dispatch = useDispatch();
  //select store data
  const supplement = useSelector((state) => state?.supplement);
  const { supplementCreated, isCreated, loading, appErr, serverErr } =
    supplement;

  //formik
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",
    },

    onSubmit: (values) => {
      //dispath the action

      const data = {
        title: values?.title,
        description: values?.description,
        image: values?.image,
      };

      dispatch(createSupplementAction(data));
      //fetch posts
    },
    validationSchema: formSchema,
  });
  useEffect(() => {
    dispatch(fetchSupplementAction(""));
  }, [dispatch]);

  if (isCreated) return <Navigate to="/supplement-templates" />;
  return (
    <div className="app_supplement-background-trainer-big-container">
      <div className="app_supplement-trainer-big-container">
        <div className="app_supplement-trainer-up-big-container">
          {" "}
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 py-3 px-3"
              >
                Title
              </label>
              <div className="mt-1">
                {/* Title */}
                <input
                  value={formik.values.title}
                  onChange={formik.handleChange("title")}
                  onBlur={formik.handleBlur("title")}
                  id="title"
                  name="title"
                  type="title"
                  autoComplete="title"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* Err msg */}
              <div className="text-red-500 px-3" dir="rtl">
                {formik?.touched?.title && formik?.errors?.title}
              </div>
            </div>
            {/* Category input goes here */}

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 px-3"
              >
                Description
              </label>
              {/* Description */}
              <textarea
                value={formik.values.description}
                onChange={formik.handleChange("description")}
                onBlur={formik.handleBlur("description")}
                rows="5"
                cols="10"
                className="rounded-lg appearance-none block w-full py-3 px-3 text-base text-center leading-tight text-gray-600   border border-gray-200 focus:border-gray-500  focus:outline-none"
                type="text"
              ></textarea>
              {/* Err msg */}
              <div className="text-red-500 px-3" dir="rtl">
                {formik?.touched?.description && formik.errors?.description}
              </div>
              {/* Image component */}
              <label
                htmlFor="password"
                className="block text-sm font-medium mt-3 mb-2 text-gray-700 px-3"
              >
                Select image to upload
              </label>

              <Container className="container bg-gray-700">
                <Dropzone
                  onBlur={formik.handleBlur("image")}
                  accept="image/jpeg, image/png"
                  onDrop={(acceptedFiles) => {
                    formik.setFieldValue("image", acceptedFiles[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="container">
                      <div
                        {...getRootProps({
                          className: "dropzone",
                          onDrop: (event) => event.stopPropagation(),
                        })}
                      >
                        <input {...getInputProps()} />

                        <p className="text-gray-300 text-lg cursor-pointer hover:text-gray-500 px-3">
                          Click here to select image
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Container>
            </div>
            <div>
              {/* Submit btn */}
              {loading ? (
                <button
                  disabled
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Loading please wait...
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="app_supplement-trainer-down-parent-container">
          {appErr || serverErr ? (
            <h1>
              {serverErr} {appErr}
            </h1>
          ) : supplementCreated?.length <= 0 ? (
            <h1>There is no Supplement yet</h1>
          ) : (
            supplementCreated?.map((supplement) => (
              <div className="app_supplement-trainer-down-big-container">
                <div className="app_supplement-trainer-down-small-container">
                  <button
                    onClick={() =>
                      dispatch(
                        deleteSupplementTemplateFromTrainerAction({
                          supplementId: supplement._id,
                        })
                      )
                    }
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm-1-6a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 11-2 0v-1h-2a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Delete
                  </button>
                  <img src={supplement?.image} alt="" />
                  <h1>{supplement?.title}</h1>
                  <h3>{supplement?.description}</h3>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
