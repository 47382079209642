import React, { useEffect } from "react";
import DateFormatter from "../../../utils/DateFormatter";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTrainersAction } from "../../../redux/slices/users/usersSlices";
import "./CoachPage.scss";
import { Link } from "react-router-dom";
const CoachPage = () => {
  function capitalizeWord(word) {
    if (typeof word === "string" && word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    // Return an empty string or handle the case when word is undefined or empty
    return ""; // You can return an empty string or any other value as needed
  }
  const dispatch = useDispatch();

  const store = useSelector((state) => state?.users);
  const { userAuth, fetchTrainers, loading, serverErr, appErr } = store;
  useEffect(() => {
    dispatch(fetchAllTrainersAction(""));
  }, [dispatch]);

  return (
    <div className="app_trainer-big-container">
      <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0  text-white">
        <h1 className="text-center  text-xl border-gray-500 mb-2 border-b-2">
          Trainers Ready To Change Your Life
        </h1>
        {/* Loo here */}
        {fetchTrainers?.length <= 0 ? (
          <h2 className="text-center text-xl"> No Trainers Found Yet</h2>
        ) : (
          fetchTrainers?.map((trainer) => (
            <div
              key={trainer._id}
              className="flex flex-wrap  -mx-3 mt-3  lg:mb-6 "
            >
              <div className="mb-2   w-full lg:w-1/4 px-3">
                <img
                  className="object-cover h-100 rounded"
                  src={trainer?.profilePhoto}
                  alt="poster"
                />
              </div>
              <div className="w-full lg:w-3/4 px-3">
                <Link to={`/trainer-profile-details/${trainer?.id}`}>
                  <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                    {capitalizeWord(trainer?.firstName)}{" "}
                    {capitalizeWord(trainer?.lastName)}
                  </h3>
                </Link>
                <p className="text-white ">{trainer?.bio}</p>
                <p className="m-3 text-black flex items-center justify-center bg-white font-bold text-lg">
                  Created At: <DateFormatter date={trainer?.createdAt} />
                </p>
                <Link to={`/trainer-profile-details/${trainer?.id}`}>
                  Read more
                </Link>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CoachPage;
