import React, { useState } from "react";

import "./NutritionPage.scss";
import CountDownCounter from "../../components/CountDownCounter/CountDownCounter";
import { client } from "../../client";
import NutritionExamples from "../../components/NutritionExamples/NutritionExamples";

const NutritionPage = () => {
  const [userData, setUserData] = useState({
    name: "",
    age: "",
    gender: "Male",
    weight: "",
    height: "",
    activity: "VeryLight",
    goal: "IncreaseWeight",
    proteins: "",
    carbohydrates: "",
    fats: "",
  });
  const [bmr, setBMR] = useState(0);
  const [tdee, setTDEE] = useState(0);
  const [calPerDay, setCalPerDay] = useState(0);
  const [dataGenerated, setDataGenerated] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const calculateBMR = () => {
    const { age, gender, weight, height } = userData;
    let bmr = 0;

    // Calculate BMR based on the Harris-Benedict equation
    if (gender === "Male") {
      bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
    } else {
      bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
    }

    return Math.round(bmr); // Return the calculated BMR rounded to the nearest integer
  };

  const calculateTDEE = (bmr, activity) => {
    let activityFactor = 1.2; // default for VeryLight activity

    // Adjust activity factor based on selected activity level
    switch (activity) {
      case "Light":
        activityFactor = 1.375;
        break;
      case "Normal":
        activityFactor = 1.55;
        break;
      case "Heavy":
        activityFactor = 1.725;
        break;
      case "VeryHeavy":
        activityFactor = 1.9;
        break;
      default:
        break;
    }

    return Math.round(bmr * activityFactor); // Calculate TDEE rounded to the nearest integer
  };

  // Handle generate function
  const handleGenerate = () => {
    const calculatedBMR = calculateBMR();
    setBMR(calculatedBMR);

    if (calculatedBMR !== 0) {
      const calculatedTDEE = calculateTDEE(calculatedBMR, userData.activity);
      setTDEE(calculatedTDEE);

      // Adjust TDEE based on the user's weight goal
      let adjustedTDEE = calculatedTDEE;
      if (userData.goal === "IncreaseWeight") {
        adjustedTDEE = Math.round(calculatedTDEE * 1.1); // Increase TDEE by 10% for weight gain
      } else if (userData.goal === "ReduceWeight") {
        adjustedTDEE = Math.round(calculatedTDEE * 0.9); // Decrease TDEE by 10% for weight loss
      }

      // Set the final adjusted TDEE
      setTDEE(adjustedTDEE);

      // Calculate calories per day based on adjusted TDEE
      setCalPerDay(calculatedTDEE);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const nutritionGenerator = {
      _type: "nutritionGenerator",
      name: userData.name,
    };

    client.create(nutritionGenerator);
  };

  return (
    <div>
      <div className="app_nutrition-background">
        <div className="app_nutrition-big-container">
          <div className="app_nutrition-left-side-up">
            <div className="app_nutrition-content-left-side-up">
              <h3 className="app_nutrition-title-left-side-up">
                Champion Data
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Name</h3>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    autoComplete="off"
                    required={true}
                  />
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Age</h3>
                  <input
                    type="number"
                    name="age"
                    onChange={handleInputChange}
                    autoComplete="off"
                    required={true}
                  />
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Weight</h3>
                  <input
                    type="number"
                    name="weight"
                    onChange={handleInputChange}
                    autoComplete="off"
                    required={true}
                  />
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Height</h3>
                  <input
                    type="number"
                    name="height"
                    onChange={handleInputChange}
                    autoComplete="off"
                    required={true}
                  />
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Gender</h3>
                  <select name="gender" onChange={handleInputChange}>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Activity</h3>
                  <select name="activity" onChange={handleInputChange}>
                    <option value="VeryLight">VeryLight</option>
                    <option value="Light">Light</option>
                    <option value="Normal">Normal</option>
                    <option value="Heavy">Heavy</option>
                    <option value="VeryHeavy">VeryHeavy</option>
                  </select>
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Goal</h3>
                  <select name="goal" onChange={handleInputChange}>
                    <option value="IncreaseWeight">IncreaseWeight</option>
                    <option value="ReduceWeight">ReduceWeight</option>
                  </select>
                </div>
                <h3 className="app_nutrition-title-left-side-up">
                  Champion Macros
                </h3>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Proteins</h3>
                  <input
                    type="number"
                    name="proteins"
                    onChange={handleInputChange}
                    autoComplete="off"
                    required={true}
                  />
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Carbohydrates</h3>
                  <input
                    type="number"
                    name="carbohydrates"
                    onChange={handleInputChange}
                    autoComplete="off"
                    required={true}
                  />
                </div>
                <div className="app_nutrition-content--left-side-up-input">
                  <h3>Fats</h3>
                  <input
                    type="number"
                    name="fats"
                    onChange={handleInputChange}
                    autoComplete="off"
                    required={true}
                  />
                </div>
                {/* Include other select elements similarly */}
                <button
                  className="app_nutrition-Generate-left-side-up"
                  onClick={() => {
                    handleGenerate();
                    setDataGenerated(true); // Set dataGenerated to true on button click
                  }}
                >
                  Generate
                </button>
              </form>
            </div>
          </div>
          <div className="app_nutrition-middle-side-up">
            <div className="app_nutrition-middle-side-up-first">
              <div className="app_nutrition-middle-side-up-playerStatic">
                Champion Statics
              </div>
            </div>
            <div className="app_nutrition-middle-side-up-playerStatic-info">
              {dataGenerated && ( // Conditionally render only if dataGenerated is true
                <>
                  <h3>
                    Name: <span>{userData.name}</span>
                  </h3>
                  <h3>
                    Age: <span>{userData.age}</span>
                  </h3>
                  <h3>
                    Weight: <span>{userData.weight}</span>
                  </h3>
                  <h3>
                    Height: <span>{userData.height}</span>
                  </h3>
                  <h3>
                    Gender: <span>{userData.gender}</span>
                  </h3>
                  <h3>
                    Activity: <span>{userData.activity}</span>
                  </h3>
                  <h3>
                    Goal: <span>{userData.goal}</span>
                  </h3>

                  {/* Display other user data here */}
                </>
              )}
            </div>
            <div className="app_nutrition-middle-side-up-first">
              <div className="app_nutrition-middle-side-up-playerStatic">
                Calories Info
              </div>
            </div>
            <div className="app_nutrition-middle-side-up-playerStatic-info-calories">
              {dataGenerated && ( // Conditionally render only if dataGenerated is true
                <>
                  <h3>
                    BMR: <span>{bmr} Cal</span>
                  </h3>
                  <h3>
                    TDEE: <span>{calPerDay} Cal</span>
                  </h3>
                  <h3>
                    Calories Per Day: <span>{tdee} Cal</span>
                  </h3>
                  <h3>
                    Calories Per Week: <span>{tdee * 7} Cal</span>
                  </h3>

                  {/* Display other user data here */}
                </>
              )}
            </div>

            <div className="app_nutrition-middle-side-up-first">
              <div className="app_nutrition-middle-side-up-playerStatic">
                Meals Per Day Analyses
              </div>
            </div>
            <div className="app_nutrition-middle-side-up-playerStatic-info-calories">
              {dataGenerated && ( // Conditionally render only if dataGenerated is true
                <>
                  <h3>
                    Proteins:{" "}
                    <span>
                      {userData.proteins && tdee
                        ? ((tdee * userData.proteins) / 100).toFixed(2)
                        : 0}{" "}
                      Cal
                    </span>
                  </h3>
                  <h3>
                    Carbohydrates:{" "}
                    <span>
                      {userData.carbohydrates && tdee
                        ? ((tdee * userData.carbohydrates) / 100).toFixed(2)
                        : 0}{" "}
                      Cal
                    </span>
                  </h3>
                  <h3>
                    Fats:{" "}
                    <span>
                      {userData.fats && tdee
                        ? ((tdee * userData.fats) / 100).toFixed(2)
                        : 0}{" "}
                      Cal
                    </span>
                  </h3>
                </>
              )}
            </div>
            <div className="app_nutrition-middle-side-up-playerStatic-info-calories">
              <h3>
                Proteins:{" "}
                <span>
                  {userData.proteins && tdee
                    ? ((tdee * userData.proteins) / 100 / 4).toFixed(2)
                    : 0}{" "}
                  (g)
                </span>
              </h3>
              <h3>
                Carbohydrates:{" "}
                <span>
                  {userData.carbohydrates && tdee
                    ? ((tdee * userData.carbohydrates) / 100 / 4).toFixed(2)
                    : 0}{" "}
                  (g)
                </span>
              </h3>
              <h3>
                Fats:{" "}
                <span>
                  {userData.fats && tdee
                    ? ((tdee * userData.fats) / 100 / 9).toFixed(2)
                    : 0}{" "}
                  (g)
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div className="app_nutrition-examples-meals-Section">
          <NutritionExamples />
        </div>
        <CountDownCounter />
      </div>
    </div>
  );
};

export default NutritionPage;
