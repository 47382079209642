import React from "react";
import "./CountDownCounter.scss";
import Countdown from "react-countdown";

import CptLogo from "../../assets/image/cpt-logo.png";
import Elite from "../../assets/image/elite.png";
const CountDownCounter = () => {
  return (
    <div className="app_Nutrition-container">
      <div className="app_Nutrition-counter-container">
        <div className="app_Nutrition-counter-up">
          Get ready for liftoff! The countdown has begun. Brace yourself for an
          experience that will redefine the way you see
        </div>
        <div className="app_Nutrition-counter-countdown">
          <Countdown
            className="app_Nutrition-counter-number"
            date={Date.now() + 10000 * 250000}
          />
        </div>
        <div className="app_Nutrition-counter-down">
          <div className="app_Nutrition-counter-left">
            {" "}
            <img
              className="app__profile-sig-1"
              src={CptLogo}
              alt="Certified Certificate Personal Trainer"
            />
            <img
              className="app__profile-sig-1"
              src={Elite}
              alt="Certified Certificate Elite Trainer"
            />
          </div>
          <div className="app_Nutrition-counter-right">
            <a href="/">
              <button className="app__profile-resume-btn-1">Go Home</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDownCounter;
