import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";
import "./WhyMe.scss";
const WhyMe = () => {
  const [whyme, setWhyme] = useState([]);

  useEffect(() => {
    const query = '*[_type == "whyme"]';

    client.fetch(query).then((data) => {
      setWhyme(data);
    });
  }, []);
  return (
    <div>
      {" "}
      <h2 className="head-text App__profile-header" id="services">
        Why Choose <span>Me</span> <br />
      </h2>
      <div className="app__whyme">
        {whyme.map((why, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5, type: "tween" }}
            className="app__whyme-items2"
            key={why.title + index}
          >
            <img src={urlFor(why.imgUrl)} alt={why.title} />
            <div className="app__whyme-about-container">
              <h2 className="app__whyme-title" style={{ marginTop: 20 }}>
                {why.title}
              </h2>
              <p className="app__whyme-desc" style={{ marginTop: 10 }}>
                {why.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default WhyMe;
