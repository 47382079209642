import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import logo from "../../../assets/image/Logo.png";
import "./TrainerNavbar.scss";
import { logoutUserAction } from "../../../redux/slices/users/usersSlices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const TrainerNavbar = () => {
  const state = useSelector((state) => state.users);
  const { userAuth } = state;
  const userId = userAuth._id;
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        <li className="app__flex app__navbar-links2 p-text">
          <Link
            className="app__navbar-links-btns"
            to={`/trainer-profile/${userId}`}
          >
            Profile
          </Link>
          <Link className="app__navbar-links-btns" to="/nutrition-templates">
            NUTRITION
          </Link>
          <Link className="app__navbar-links-btns" to="/workout-templates">
            WORKOUT
          </Link>
          <Link className="app__navbar-links-btns" to="/supplement-templates">
            SUPPLEMENTs
          </Link>
          <Link
            className="app__navbar-links-btns"
            to="/training-guidline-templates"
          >
            GuidLines
          </Link>
          <Link
            className="app__navbar-links-btns"
            to="/training-tools-templates"
          >
            Tools
          </Link>
        </li>
      </ul>
      <div>
        {" "}
        <a href="/">
          <button
            className="app__profile-resume-btn-1"
            onClick={() => dispatch(logoutUserAction())}
          >
            Logout
          </button>
        </a>
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links-ul">
              <li>
                <Link
                  className="app__navbar-links-btns1"
                  to={`/trainer-profile/${userId}`}
                  onClick={() => setToggle(false)}
                >
                  PROFILE
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/nutrition-templates"
                  onClick={() => setToggle(false)}
                >
                  NUTRITION
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/workout-templates"
                  onClick={() => setToggle(false)}
                >
                  WORKOUT
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/supplement-templates"
                  onClick={() => setToggle(false)}
                >
                  SUPPLEMENTS
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/training-guidline-templates"
                  onClick={() => setToggle(false)}
                >
                  GuidLines
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/training-tools-templates"
                  onClick={() => setToggle(false)}
                >
                  GuidLines
                </Link>

                <Link
                  className="app__navbar-links-btns1"
                  to="/"
                  onClick={() => {
                    setToggle(false);
                    dispatch(logoutUserAction());
                  }}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default TrainerNavbar;
