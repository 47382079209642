import React, { useEffect, useState } from "react";
import "./NutritionTemplate.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createnutritiontemplateAction,
  deleteNutritionTemplateFromTrainerAction,
  fetchNutritionsAction,
} from "../../../redux/slices/nutrition/nutritionSlices";
import { Navigate } from "react-router-dom";
import DateFormatter from "../../../utils/DateFormatter";

const formSchema = Yup.object({
  templateName: Yup.string().required("يرجى إدخال إسم القالب"),
  // dayName: Yup.string().required("يرجى إدخال أسم اليوم"),
  // dayNotes: Yup.string().required("يرجى إ"),
  calories: Yup.string().required("يرجى إدخال عدد السعرات الحرارية في اليوم"),
  protein: Yup.string().required("يرجى إدخال كمية البروتين بالجرام"),
  carbs: Yup.string().required("يرجى إدخال كمية الكارب بالجرام"),
  fats: Yup.string().required("يرجى إدخال كمية الدهون بالجرام"),
});
const NutritionTemplate = () => {
  const [meals, setMeals] = useState({
    breakfast: [],
    snack1: [],
    lunch: [],
    snack2: [],
    dinner: [],
  });
  const dispatch = useDispatch();

  //select store data
  const nutrition = useSelector((state) => state?.nutrition);
  const { nutritionCreated, isCreated, loading, appErr, serverErr } = nutrition;
  //formik
  const formik = useFormik({
    initialValues: {
      templateName: "",
      dayName: "",
      dayNotes: "",
      calories: "",
      protein: "",
      carbs: "",
      fats: "",
      breakfast: [{ name: "", quantity: "", notes: "" }],
      snack1: [{ name: "", quantity: "", notes: "" }],
      lunch: [{ name: "", quantity: "", notes: "" }],
      snack2: [{ name: "", quantity: "", notes: "" }],
      dinner: [{ name: "", quantity: "", notes: "" }],
    },

    onSubmit: (values) => {
      //dispath the action

      const data = {
        templateName: values?.templateName,
        dayName: values?.dayName,
        dayNotes: values?.dayNotes,
        calories: values?.calories,
        protein: values?.protein,
        carbs: values?.carbs,
        fats: values?.fats,
        meals,
      };

      dispatch(createnutritiontemplateAction(data));
    },

    validationSchema: formSchema,
  });

  const handleAddRow = (mealType) => {
    setMeals((prevMeals) => ({
      ...prevMeals,
      [mealType]: [
        ...prevMeals[mealType],
        { name: "", quantity: "", notes: "" },
      ],
    }));
  };

  const handleRemoveRow = (mealType, index) => {
    const updatedMeals = meals[mealType].filter((_, i) => i !== index);
    setMeals({ ...meals, [mealType]: updatedMeals });
  };
  useEffect(() => {
    dispatch(fetchNutritionsAction(""));
  }, [dispatch]);

  if (isCreated) return <Navigate to="/nutrition-templates" />;
  return (
    <div className="app_trainer-nutrition-templates-big-container">
      <div className="app_trainer-nutrition-templates-small-container">
        <div className="app_trainer-nutrition-templates-small-up-container">
          <div className="app_trainer-nutrition-templates-small-up1-container">
            إصنع قالب تغذية جديد جاهز للإستخدام
            {appErr || serverErr ? (
              <p className="mt-2 text-center text-lg text-red-600">
                {serverErr} {appErr}
              </p>
            ) : null}
          </div>
          <div className="app_trainer-nutrition-templates-big-middle-form-container">
            <form
              onSubmit={formik.handleSubmit}
              className="app_trainer-nutrition-templates-small-middle-form-container"
            >
              <div className="app_trainer-nutrition-templates-small-left-form-container">
                <input
                  value={formik.values.templateName}
                  onChange={formik.handleChange("templateName")}
                  onBlur={formik.handleBlur("templateName")}
                  id="templateName"
                  name="templateName"
                  className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Template Name"
                />
                <div className="text-red-400 text-xs" dir="rtl">
                  {" "}
                  {formik.touched.templateName && formik.errors.templateName}
                </div>
                <input
                  value={formik.values.dayName}
                  onChange={formik.handleChange("dayName")}
                  id="dayName"
                  name="dayName"
                  className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Day Name"
                />
                <input
                  value={formik.values.dayNotes}
                  onChange={formik.handleChange("dayNotes")}
                  id="dayNotes"
                  name="dayNotes"
                  className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Day Notes"
                />
                <input
                  value={formik.values.calories}
                  onChange={formik.handleChange("calories")}
                  onBlur={formik.handleBlur("calories")}
                  id="calories"
                  name="calories"
                  className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                  type="number"
                  placeholder="Calories"
                />
                <div className="text-red-400 text-xs	" dir="rtl">
                  {" "}
                  {formik.touched.calories && formik.errors.calories}
                </div>
                <input
                  value={formik.values.protein}
                  onChange={formik.handleChange("protein")}
                  onBlur={formik.handleBlur("protein")}
                  id="protein"
                  name="protein"
                  className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                  type="number"
                  placeholder="Proteins"
                />
                <div className="text-red-400 text-xs	" dir="rtl">
                  {" "}
                  {formik.touched.protein && formik.errors.protein}
                </div>
                <input
                  value={formik.values.carbs}
                  onChange={formik.handleChange("carbs")}
                  onBlur={formik.handleBlur("carbs")}
                  id="carbs"
                  name="carbs"
                  className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                  type="number"
                  placeholder="Carbs"
                />{" "}
                <div className="text-red-400 text-xs	" dir="rtl">
                  {" "}
                  {formik.touched.carbs && formik.errors.carbs}
                </div>
                <input
                  value={formik.values.fats}
                  onChange={formik.handleChange("fats")}
                  onBlur={formik.handleBlur("fats")}
                  id="fats"
                  name="fats"
                  className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                  type="number"
                  placeholder="Fats"
                />
                <div className="text-red-400 text-xs	" dir="rtl">
                  {" "}
                  {formik.touched.fats && formik.errors.fats}
                </div>
                {/* Submit btn */}
              </div>

              <div className="app_trainer-nutrition-templates-small-middle1-form-container">
                {Object.keys(meals).map((mealType) => (
                  <div
                    key={mealType}
                    className="app_trainer-nutrition-templates-small-middle1-form-everybox"
                  >
                    <h3>{mealType}</h3>
                    {meals[mealType].map((meal, index) => (
                      <div key={index}>
                        <input
                          name={`${mealType}[${index}].name`}
                          value={meal.name}
                          onChange={(e) => {
                            const { value } = e.target;
                            setMeals((prevMeals) => ({
                              ...prevMeals,
                              [mealType]: prevMeals[mealType].map((m, i) =>
                                i === index ? { ...m, name: value } : m
                              ),
                            }));
                          }}
                          placeholder="Name"
                        />
                        <input
                          name={`${mealType}[${index}].quantity`}
                          value={meal.quantity}
                          onChange={(e) => {
                            const { value } = e.target;
                            setMeals((prevMeals) => ({
                              ...prevMeals,
                              [mealType]: prevMeals[mealType].map((m, i) =>
                                i === index ? { ...m, quantity: value } : m
                              ),
                            }));
                          }}
                          placeholder="Quantity"
                        />
                        <input
                          name={`${mealType}[${index}].notes`}
                          value={meal.notes}
                          onChange={(e) => {
                            const { value } = e.target;
                            setMeals((prevMeals) => ({
                              ...prevMeals,
                              [mealType]: prevMeals[mealType].map((m, i) =>
                                i === index ? { ...m, notes: value } : m
                              ),
                            }));
                          }}
                          placeholder="Notes"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveRow(mealType, index)}
                          className="w-10 flex justify-center my-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          (-)
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => handleAddRow(mealType)}
                      className="w-10 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      (+)
                    </button>
                  </div>
                ))}
              </div>
              {loading ? (
                <button
                  type="submit"
                  disabled
                  className="w-full flex justify-center my-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Loading please wait...
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center my-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </button>
              )}
            </form>
          </div>
        </div>
        <div className="app_nutrition-bottom-big-container">
          <div className="app_nutrition-bottom-small-container">
            {appErr || serverErr ? (
              <h1>
                {serverErr} {appErr}
              </h1>
            ) : nutritionCreated?.length <= 0 ? (
              <h1>There is no Diet Plans yet</h1>
            ) : (
              nutritionCreated?.map((nutrition) => (
                <div className="app_nutrition-bottom-item-container">
                  <button
                    onClick={() =>
                      dispatch(
                        deleteNutritionTemplateFromTrainerAction({
                          nutritionId: nutrition._id,
                        })
                      )
                    }
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm-1-6a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 11-2 0v-1h-2a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Delete
                  </button>
                  <h3>Name: {nutrition?.templateName}</h3>

                  <h3>Calories: {nutrition?.nutritionTemplate?.calories}</h3>
                  <h3>Protein: {nutrition?.nutritionTemplate?.protein}</h3>
                  <h3>Carbs: {nutrition?.nutritionTemplate?.carbs}</h3>
                  <h3>Fats: {nutrition?.nutritionTemplate?.fats}</h3>

                  <div className="flex space-x-1 text-sm text-black-500">
                    <time>
                      <DateFormatter date={nutrition?.createdAt} />
                    </time>
                    <span aria-hidden="true">&middot;</span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NutritionTemplate;
