import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";
import "./Awards.scss";

const Awards = () => {
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    const query = '*[_type == "awards"]';

    client.fetch(query).then((data) => {
      setAwards(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text App__profile-header" id="awards">
        Awards <span>Achieved</span> <br />
      </h2>
      <div className="app__awards">
        {awards.map((award, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5, type: "tween" }}
            className="app__awards-items"
            key={award.title + index}
          >
            <img src={urlFor(award.imgUrl)} alt={award.title} />
            <div className="app__awards-about-container">
              <a
                className="app__awards-desc"
                href={award.link}
                target="_blank"
                rel="noreferrer"
              >
                {award.title}
              </a>

              {/* <p className="app__workat-desc" style={{ marginTop: 10 }}>
                {work.date}
              </p> */}

              {/* <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.desc}</span>
                {work.description}
              </p>
              <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.addressInfo}</span>
                {work.address}
              </p>
              <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.mobileInfo}</span>
                {work.mobile}
              </p> */}
              {/* <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.pageInfo}</span>
                <a
                  className="app__workat-link"
                  href={work.page}
                  target="_blank"
                  rel="noreferrer"
                >
                  من هنا
                </a>
              </p>
              <p className="app__workat-desc" style={{ marginTop: 10 }}>
                <span className="app__workat-sp">{work.offerInfo}</span>
                {work.info}
              </p> */}
            </div>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default Awards;
