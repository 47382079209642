import React, { useEffect } from "react";
import "./ClientNutritionfirstpage.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchAllNutritionPlansWithClient } from "../../../redux/slices/nutrition/nutritionSlices";
import { useDispatch, useSelector } from "react-redux";
const ClientNutritionfirstpage = () => {
  const dispatch = useDispatch();

  //select store data
  const nutrition = useSelector((state) => state?.nutrition);
  const { nutritionClient, isCreated, loading, appErr, serverErr } = nutrition;

  useEffect(() => {
    dispatch(fetchAllNutritionPlansWithClient(""));
  }, [dispatch]);
  return (
    <div className="app_nutrition-first-page-container">
      <div class="  container">
        <div class=" row">
          <div class="app_nutrition-first-page-midcontainer ">
            {appErr || serverErr ? (
              <h1>
                {serverErr} {appErr}
              </h1>
            ) : nutritionClient?.length <= 0 ? (
              <h1>There is no Nutrition added yet</h1>
            ) : (
              nutritionClient?.map((nutrition, index) => (
                <div key={index} class="serviceBox">
                  <h3 class="title">{nutrition?.dayName}</h3>
                  <h4> Calories: {nutrition?.nutritionTemplate?.calories}</h4>
                  <h4> Protein: {nutrition?.nutritionTemplate?.protein}</h4>
                  <h4> Carbohydrates: {nutrition?.nutritionTemplate?.carbs}</h4>
                  <h4> Fats: {nutrition?.nutritionTemplate?.fats}</h4>

                  <span>
                    <a href={`/nutrition-template/${nutrition?._id}`}>
                      Read More
                    </a>
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientNutritionfirstpage;
