import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addNutritionToUserAction,
  removeNutritionFromUserAction,
} from "../../../redux/slices/users/usersSlices";

import { useDispatch, useSelector } from "react-redux";
import DateFormatter from "../../../utils/DateFormatter";

import { fetchAllNutritionPlansWithTrainer } from "../../../redux/slices/nutrition/nutritionSlices";
const ClientProfileNNutritionPage = () => {
  // Initialize selectedSupplementId with an empty string initially
  const [selectedNutritionId, setSelectedNutritionId] = useState("");

  const handleNutritionSelection = (event) => {
    setSelectedNutritionId(event.target.value);
  };

  const { id } = useParams();

  function capitalizeWord(word) {
    if (typeof word === "string" && word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    // Return an empty string or handle the case when word is undefined or empty
    return ""; // You can return an empty string or any other value as needed
  }

  const dispatch = useDispatch();

  // Assuming you have a list of available supplements in your Redux state
  const users = useSelector((state) => state?.users);
  const { nutritionDetail } = users;

  // Assuming you have a list of available supplements in your Redux state
  const nutrtions = useSelector((state) => state?.nutrition);
  const { nutritionGet } = nutrtions;
  // Fetch supplement data when the component mounts
  useEffect(() => {
    dispatch(fetchAllNutritionPlansWithTrainer());
  }, [dispatch]);

  // Update selectedSupplementId when supplementCreated changes
  useEffect(() => {
    // Check if supplementCreated is not empty and update selectedSupplementId with the first supplement's ID
    if (nutritionGet?.length > 0 && !selectedNutritionId) {
      setSelectedNutritionId(nutritionGet[0]?.id || "");
    }
  }, [nutritionGet, selectedNutritionId]);
  const handleAddSupplement = () => {
    if (selectedNutritionId) {
      dispatch(
        addNutritionToUserAction({
          clientId: id,
          nutritionId: selectedNutritionId,
        })
      );
    }
  };

  return (
    <div>
      <div className="flex justify-center place-items-start flex-wrap md:mb-0">
        <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0 text-white">
          <h1 className="text-center text-xl border-gray-500 mb-2 border-b-2">
            Nutrition Plans
          </h1>

          {nutritionDetail?.nutrition?.length <= 0 ? (
            <h2 className="text-center text-xl">No Nutrition Added Yet</h2>
          ) : (
            nutritionDetail?.nutrition?.map((nutrition) => (
              <div
                key={nutrition._id}
                className="flex justify-start align-middle flex-wrap  -mx-3 mt-3   lg:mb-6"
              >
                <div className="w-full lg:w-3/4 px-3">
                  <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                    {capitalizeWord(nutrition?.templateName)}
                  </h3>
                  <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                    {capitalizeWord(nutrition?.dayName)}
                  </h3>
                  <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                    {capitalizeWord(nutrition?.dayNotes)}
                  </h3>
                  <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                    <button
                      onClick={() =>
                        dispatch(
                          removeNutritionFromUserAction({
                            clientId: id,
                            nutritionId: nutrition?._id,
                          })
                        )
                      }
                      type="button"
                      className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    >
                      Remove
                    </button>
                  </h3>

                  <p className="text-white ">
                    Meals:{" "}
                    {Object.keys(nutrition?.nutritionTemplate?.meals)?.length ||
                      0}
                  </p>
                  <p className="card-text">
                    Calories: {nutrition?.nutritionTemplate?.calories}
                  </p>

                  <p className="card-text">
                    Protein: {nutrition?.nutritionTemplate?.protein}
                  </p>
                  <p className="card-text">
                    Carbs: {nutrition?.nutritionTemplate?.carbs}
                  </p>
                  <p className="card-text">
                    Fats: {nutrition?.nutritionTemplate?.fats}
                  </p>

                  <p className="m-3 text-black bg-white font-bold text-lg">
                    Created At:{" "}
                    <DateFormatter
                      date={nutrition?.nutritionTemplate?.createdAt}
                    />
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="flex justify-center align-middle items-center mb-4">
        <select
          className="p-2 border text-black border-gray-300 rounded-md mr-2"
          onChange={handleNutritionSelection}
          value={selectedNutritionId}
        >
          {/* Map over available supplements and create options */}
          {nutritionGet?.map((nutrition) => (
            <option key={nutrition.id} value={nutrition.id}>
              {nutrition?.templateName}
            </option>
          ))}
        </select>
        <button
          onClick={handleAddSupplement}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add Nutrition Plan
        </button>
      </div>
    </div>
  );
};

export default ClientProfileNNutritionPage;
