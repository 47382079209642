import React, { useState, useEffect } from "react";
import "./Hero.scss";
import videoBg from "../../assets/videos/video-hero.mp4";
import { motion } from "framer-motion";
import { client } from "../../client";

const Hero = () => {
  const [heros, setHeros] = useState([]);
  useEffect(() => {
    const query = '*[_type== "heroVideo"]';
    client.fetch(query).then((data) => setHeros(data));
  }, []);

  return (
    <div className="main" id="home">
      <div className="overlay"></div>
      <video src={videoBg} autoPlay loop muted />
      <div className="content">
        {heros.map((hero, index) => (
          <motion.div className="app__profile-item" key={hero.title + index}>
            <h1 className="app__profile-header">{hero.title}</h1>
            <h2 className="app__profile-sub">{hero.subTitle}</h2>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Hero;
