import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";

import "./Certificate.scss";
const Certificate = () => {
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    const query = '*[_type == "certificates"]';

    client.fetch(query).then((data) => {
      setCertificates(data);
    });
  }, []);

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  return (
    <div>
      {" "}
      <div className="app__certificates">
        {certificates.map((certificate, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5, type: "tween" }}
            className="app__certificates-items2"
            key={certificate.title + index}
          >
            <img
              src={urlFor(certificate.imgUrl)}
              alt={certificate.title}
              onClick={() => openImageInNewTab(urlFor(certificate.imgUrl))}
            />
            <div className="app__certificates-about-container">
              <h2 className="app__certificates-title" style={{ marginTop: 20 }}>
                {certificate.title}
              </h2>
              <p className="app__certificates-desc" style={{ marginTop: 10 }}>
                {certificate.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Certificate;
