import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ClientProtectedRoute = ({ element: Element, ...rest }) => {
  // check if the user login
  const user = useSelector((state) => state?.users);
  const { userAuth } = user;
  return userAuth?.isClient ? <Outlet /> : <Navigate to="/" />;
};

export default ClientProtectedRoute;
