import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { MailIcon } from "@heroicons/react/outline";

// import "./TrainerProfile.scss";
import {
  fetchTrainerProfileAction,
  fetchClientSupplementAction,
  fetchClientNutritionAction,
  fetchClientGuidelineAction,
  fetchClientToolAction,
  fetchClientWorkoutAction,
} from "../../../redux/slices/users/usersSlices";

import { useDispatch, useSelector } from "react-redux";
import DateFormatter from "../../../utils/DateFormatter";
import LoadingComponent from "../../../utils/LoadingComponent";
import ClientProfilePageSupplement from "../SupplementTemplates/ClientProfilePageSupplement";
import ClientProfileNNutritionPage from "../NutritionTemplates/ClientProfileNNutritionPage";
import ClientProfilePageGuideLine from "../TrainingGuidLine/ClientProfilePageGuideLine";
import ClientProfilePageTool from "../TrainingTools/ClientProfilePageTool";
import ClientProfilePageWorkout from "../WorkoutTemplates/ClientProfilePageWorkout";

const ClientProfilePage = () => {
  const { id } = useParams();

  function capitalizeWord(word) {
    if (typeof word === "string" && word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    // Return an empty string or handle the case when word is undefined or empty
    return ""; // You can return an empty string or any other value as needed
  }
  const dispatch = useDispatch();

  //User data from store
  const users = useSelector((state) => state?.users);
  const { trainerDetail, loading, appErr, ServerErr } = users;
  //Fetch user Profile
  useEffect(() => {
    dispatch(fetchClientWorkoutAction(id));
  }, [id, dispatch]);
  //Fetch user Profile
  useEffect(() => {
    dispatch(fetchTrainerProfileAction(id));
  }, [id, dispatch]);
  //Fetch user Profile
  useEffect(() => {
    dispatch(fetchClientSupplementAction(id));
  }, [id, dispatch]);
  //Fetch user Profile
  useEffect(() => {
    dispatch(fetchClientNutritionAction(id));
  }, [id, dispatch]);
  //Fetch user Profile
  useEffect(() => {
    dispatch(fetchClientGuidelineAction(id));
  }, [id, dispatch]);
  //Fetch user Profile
  useEffect(() => {
    dispatch(fetchClientToolAction(id));
  }, [id, dispatch]);

  return (
    <>
      <div className="min-h-screen flex justify-center items-start pt-40">
        {loading ? (
          <LoadingComponent />
        ) : appErr || ServerErr ? (
          <h2>
            {ServerErr} {appErr}
          </h2>
        ) : (
          <div className=" flex overflow-hidden ">
            {/* h-screen */}
            {/* Static sidebar for desktop */}

            <div className="flex flex-col min-w-0 flex-1 overflow-hidden pt-24">
              <div className="flex-1 relative z-0 flex overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                  <article>
                    {/* Profile header */}
                    <div>
                      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 ">
                        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5 ">
                          <div className="flex mt-20  ">
                            <img
                              className="h-24 w-24 rounded-full  ring-4 ring-white  sm:h-32 sm:w-32"
                              src={trainerDetail?.profilePhoto}
                              alt={trainerDetail?.fullName}
                            />
                          </div>
                          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1 ">
                            <div className=" flex flex-col 2xl:block mt-10 min-w-0 flex-1 ">
                              <h1 className="text-2xl font-bold text-white ">
                                {capitalizeWord(trainerDetail?.firstName)}{" "}
                                {capitalizeWord(trainerDetail?.lastName)}
                                {/* Display if verified or not */}
                                {trainerDetail?.isAccountVerified ? (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-green-600 text-gray-300">
                                    Account Verified
                                  </span>
                                ) : (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-red-600 text-gray-300">
                                    Unverified Account
                                  </span>
                                )}
                                {trainerDetail?.isTrainer ? (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-green-600 text-gray-300">
                                    Trainer
                                  </span>
                                ) : (
                                  <span className="inline-flex ml-2 items-center px-3 py-0.5  rounded-lg text-sm font-medium bg-red-600 text-gray-300">
                                    Client
                                  </span>
                                )}
                              </h1>
                              <p className="m-3 text-white text-lg">
                                Date Joined:{" "}
                                <DateFormatter
                                  date={trainerDetail?.createdAt}
                                />
                              </p>
                              <p className="m-3 text-black bg-white pl-5 text-xl">
                                {trainerDetail?.bio}
                              </p>
                            </div>

                            <div className="mt-6  flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                              <Link
                                to={`/send-mail?email=${trainerDetail?.email}`}
                                className="inline-flex justify-center bg-indigo-900 px-4 py-2 border border-yellow-700 shadow-sm text-sm font-medium rounded-md text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                              >
                                <MailIcon
                                  className="-ml-1 mr-2 h-5 w-5 text-gray-200"
                                  aria-hidden="true"
                                />
                                <span className="text-base mr-2  text-bold text-yellow-500">
                                  Send Message
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Tabs */}
                    <div className="mt-6 sm:mt-2 2xl:mt-5">
                      <div className="border-b border-red-900">
                        <div className="max-w-5xl mx-auto "></div>
                      </div>
                      <ClientProfilePageSupplement />
                      <ClientProfileNNutritionPage />
                      <ClientProfilePageGuideLine />
                      <ClientProfilePageTool />
                      <ClientProfilePageWorkout />
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientProfilePage;
