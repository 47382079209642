import React from "react";
import "./Footer.scss";
const Footer = () => {
  // Creating Date Object
  var dateobj = new Date();

  // Year from the above object
  // is being fetched using getFullYear()
  var dateObject = dateobj.getFullYear();
  return (
    <div className="app__footer-container">
      Made By Rahmou, All Rights Reserved {dateObject}
    </div>
  );
};

export default Footer;
