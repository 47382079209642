import React, { useState, useEffect } from "react";

import { motion } from "framer-motion";

import { urlFor, client } from "../../client";
import "./Brand.scss";
const Brand = () => {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    const brandsQuery = '*[_type == "brands"]';

    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);
  return (
    <div className="app__brand-container">
      <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5, type: "tween" }}
            key={brand._id}
          >
            <img src={urlFor(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Brand;
