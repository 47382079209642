import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllNutritionPlansWithClient,
  fetchOneNutritionPlansWithClient,
} from "../../../redux/slices/nutrition/nutritionSlices";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
const ClientOneNutrition = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  //select store data
  const nutrition = useSelector((state) => state?.nutrition);
  const {
    nutritionClient,
    nutritionselected,
    isCreated,
    loading,
    appErr,
    serverErr,
  } = nutrition;

  useEffect(() => {
    dispatch(fetchAllNutritionPlansWithClient(""));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchOneNutritionPlansWithClient(id));
  }, [dispatch]);

  const calculateWeekNumber = (index) => {
    return Math.floor(index / 7) + 1;
  };
  return (
    <div className="app_client-nutrition-background-container">
      <div className="app_client-nutrition-big-container">
        <h1>Nutrition Plans</h1>
        <div className="app_client-nutrition-big-box-center">
          {appErr || serverErr ? (
            <h1>
              {serverErr} {appErr}
            </h1>
          ) : nutritionClient?.length <= 0 ? (
            <h1>There is no Nutrition added yet</h1>
          ) : (
            <div className="app_client-bottom-nutrition-item-container">
              <div className="nutrition-header">
                <h2>
                  {" "}
                  Calories: {nutritionselected?.nutritionTemplate?.calories}
                </h2>
                <h2>
                  {" "}
                  Protein: {nutritionselected?.nutritionTemplate?.protein}
                </h2>
                <h2>
                  {" "}
                  Carbohydrates: {nutritionselected?.nutritionTemplate?.carbs}
                </h2>
                <h2> Fats: {nutritionselected?.nutritionTemplate?.fats}</h2>
              </div>
              <h2> Notes: {nutritionselected?.dayNotes}</h2>

              <h1>BreakFast</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400 ">
                    <tr>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        QTY (g)
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>

                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {nutritionselected?.nutritionTemplate?.meals?.breakfast?.map(
                      (nutrition, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.quantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.notes}
                          </td>

                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <h1>Snack 1</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400 ">
                    <tr>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        QTY (g)
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>

                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {nutritionselected?.nutritionTemplate?.meals?.snack1?.map(
                      (nutrition, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.quantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.notes}
                          </td>

                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <h1>Lunch</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400 ">
                    <tr>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        QTY (g)
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>

                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {nutritionselected?.nutritionTemplate?.meals?.lunch?.map(
                      (nutrition, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.quantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.notes}
                          </td>

                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <h1>Snack 2</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400 ">
                    <tr>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        QTY (g)
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>

                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {nutritionselected?.nutritionTemplate?.meals?.snack2?.map(
                      (nutrition, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.quantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.notes}
                          </td>

                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <h1>Dinner</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400 ">
                    <tr>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        QTY (g)
                      </th>
                      <th className="px-6 py-3 text-left text-xl font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>

                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {nutritionselected?.nutritionTemplate?.meals?.dinner?.map(
                      (nutrition, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.quantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-xl font-extrabold text-black">
                            {nutrition?.notes}
                          </td>

                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientOneNutrition;
