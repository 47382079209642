import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";

import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

//Redirect Action
const resetPasswordAction = createAction("password/reset");
//Register functions
export const registerUserAction = createAsyncThunk(
  "users/register",
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${baseUrl}/users/register`,
        user,
        config
      );

      return data;
    } catch (err) {
      if (!err?.response) {
        throw err;
      }
      return rejectWithValue(err?.response?.data);
    }
  }
);

//login the user
export const loginUserAction = createAsyncThunk(
  "users/login",
  async (userData, { rejectWithValue, getState, dispatch }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      //make http call
      const { data } = await axios.post(
        `${baseUrl}/users/login`,
        userData,
        config
      );
      //save user into local storage
      localStorage.setItem("userInfo", JSON.stringify(data));

      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//profile
export const userProfileAction = createAsyncThunk(
  "users/profile",
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      // Fetch user profile
      const profileResponse = await axios.get(
        `${baseUrl}/users/profile/${userAuth._id}`,
        config
      );

      // Fetch user followers
      const followersResponse = await axios.get(
        `${baseUrl}/users/followers/${userAuth._id}`,
        config
      );

      // Extract data from the responses
      const profileData = profileResponse.data;
      const followersData = followersResponse.data;

      // Return an object containing both profile data and followers
      return { profile: profileData, followers: followersData };
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//update profile photo
export const createUpdateProfilePhoto = createAsyncThunk(
  "user/profile-photo",
  async (userImg, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const formData = new FormData();
      formData.append("image", userImg?.image);

      //http call

      const { data } = await axios.put(
        `${baseUrl}/users/profilephoto-upload`,
        formData,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//Update User Profile
export const updateUserProfileAction = createAsyncThunk(
  "users/update",
  async (userData, { rejectWithValue, getState, dispatch }) => {
    try {
      //get user token
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseUrl}/users`,
        {
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          email: userData?.email,
          bio: userData?.bio,
        },
        config
      );

      return data;
    } catch (err) {
      if (!err?.response) {
        throw err;
      }
      return rejectWithValue(err?.response?.data);
    }
  }
);

//Update User Password
export const updateUserPasswordAction = createAsyncThunk(
  "users/password",
  async (password, { rejectWithValue, getState, dispatch }) => {
    try {
      //get user token
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseUrl}/users/password`,
        {
          password,
        },
        config
      );
      dispatch(resetPasswordAction());
      return data;
    } catch (err) {
      if (!err?.response) {
        throw err;
      }
      return rejectWithValue(err?.response?.data);
    }
  }
);

//reset Password Token generator
export const passwordResetTokenAction = createAsyncThunk(
  "password/token",
  async (email, { rejectWithValue, getState, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${baseUrl}/users/forget-password-token`,
        { email },
        config
      );

      return data;
    } catch (err) {
      if (!err?.response) {
        throw err;
      }
      return rejectWithValue(err?.response?.data);
    }
  }
);

//Password reset
export const passwordResetAction = createAsyncThunk(
  "password/reset",
  async (user, { rejectWithValue, getState, dispatch }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //http call
    try {
      const { data } = await axios.put(
        `${baseUrl}/users/reset-password`,
        { password: user?.password, token: user?.token },
        config
      );
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Fetch all Trainers
export const fetchAllTrainersAction = createAsyncThunk(
  "/trainers/gettrainers",
  async (trainer, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/users/trainers-studio`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Fetch spacific Trainers
export const fetchTrainerProfileAction = createAsyncThunk(
  "/trainer/details",
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/users/profile/${id}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Fetch spacific Trainers
export const fetchClientSupplementAction = createAsyncThunk(
  "/Client/supplements",
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/supplement/client-supplement/${id}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//Fetch spacific Trainers
export const fetchClientNutritionAction = createAsyncThunk(
  "/Client/nutrition",
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/nutritiontemplates/client-nutrition/${id}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Add Supplement To Client
export const addSupplementToUserAction = createAsyncThunk(
  "/supplement/add",
  async ({ clientId, supplementId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/supplement/recommendation/${clientId}`, // Sending clientId as part of the URL
        { supplementId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//Remove Supplement from Client
export const removeSupplementFromUserAction = createAsyncThunk(
  "/supplement/remove",
  async ({ clientId, supplementId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/supplement/remove-supplement/${clientId}`, // Sending clientId as part of the URL
        { supplementId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------------------
//Add workout To Client
//-------------------------------------------
export const addWorkoutToUserAction = createAsyncThunk(
  "workout/add",
  async ({ clientId, workoutId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/workouttemplate/give-client-workout/${clientId}`, // Sending clientId as part of the URL
        { workoutId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------------------
//remove Workout from Client
//-------------------------------------------
export const removeWorkoutFromUserAction = createAsyncThunk(
  "workout/remove",
  async ({ clientId, workoutId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/workouttemplate/remove-client-workout/${clientId}`, // Sending clientId as part of the URL
        { workoutId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------------------
//fetch all Workouts with Client
//-------------------------------------------
export const fetchClientWorkoutAction = createAsyncThunk(
  "Client/workout",
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/workouttemplate/trainer-workout/${id}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------------------
//Add Tools To Client
//-------------------------------------------
export const addToolsToUserAction = createAsyncThunk(
  "tool/add",
  async ({ clientId, toolId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/tool/give-client-tool/${clientId}`, // Sending clientId as part of the URL
        { toolId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------------------
//remove tools from Client
//-------------------------------------------
export const removeToolFromUserAction = createAsyncThunk(
  "tool/remove",
  async ({ clientId, toolId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/tool/remove-client-tool/${clientId}`, // Sending clientId as part of the URL
        { toolId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------------------
//fetch all Tools with Client
//-------------------------------------------
export const fetchClientToolAction = createAsyncThunk(
  "Client/tool",
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/tool/trainer-tool/${id}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//------------------
//-------------------------------------------
//Add Guideline To Client
//-------------------------------------------
export const addGudelineToUserAction = createAsyncThunk(
  "guideline/add",
  async ({ clientId, guidelineId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/guideline/give-client-guideline/${clientId}`, // Sending clientId as part of the URL
        { guidelineId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------------------
//remove Guideline from Client
//-------------------------------------------
export const removeGudelineFromUserAction = createAsyncThunk(
  "guideline/remove",
  async ({ clientId, guidelineId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/guideline/remove-client-guideline/${clientId}`, // Sending clientId as part of the URL
        { guidelineId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------------------
//fetch all Guideline with Client
//-------------------------------------------
export const fetchClientGuidelineAction = createAsyncThunk(
  "Client/guideline",
  async (id, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/guideline/client-guidelines/${id}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Add nutrition To Client
export const addNutritionToUserAction = createAsyncThunk(
  "/nutrition/add",
  async ({ clientId, nutritionId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/nutritiontemplates/give-client-nutrition/${clientId}`, // Sending clientId as part of the URL
        { nutritionId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Remove Nutrition from Client
export const removeNutritionFromUserAction = createAsyncThunk(
  "/nutrition/remove",
  async ({ clientId, nutritionId }, { rejectWithValue, getState }) => {
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      const { data } = await axios.put(
        `${baseUrl}/nutritiontemplates/remove-client-nutrition/${clientId}`, // Sending clientId as part of the URL
        { nutritionId }, // Sending supplementId in the request body
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Follow Trainers
export const followTrainerAction = createAsyncThunk(
  "/trainer/follow",
  async (userToFollowId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.put(
        `${baseUrl}/users/follow`,
        {
          followId: userToFollowId,
        },
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//unFollow Trainers
export const unfollowTrainerAction = createAsyncThunk(
  "/trainer/unfollow",
  async (userToNotFollow, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;

    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.put(
        `${baseUrl}/users/unfollow`,
        {
          unfollowId: userToNotFollow,
        },
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//logout the user
export const logoutUserAction = createAsyncThunk(
  "/user/logout",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      localStorage.removeItem("userInfo");
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// get user from local storage
const userLoginFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
//slices
const usersSlices = createSlice({
  name: "users",
  initialState: {
    userAuth: userLoginFromStorage,
  },
  extraReducers: (builder) => {
    //register users
    builder.addCase(registerUserAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(registerUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.registered = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(registerUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //Reset Password Token users
    builder.addCase(passwordResetTokenAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(passwordResetTokenAction.fulfilled, (state, action) => {
      state.loading = false;
      state.passwordToken = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(passwordResetTokenAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //Password reset
    builder.addCase(passwordResetAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(passwordResetAction.fulfilled, (state, action) => {
      state.loading = false;
      state.passwordReset = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(passwordResetAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //login the user
    builder.addCase(loginUserAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(loginUserAction.fulfilled, (state, action) => {
      state.userAuth = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(loginUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //User Profile
    builder.addCase(userProfileAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(userProfileAction.fulfilled, (state, action) => {
      state.profile = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(userProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //User Update Profile Photo
    builder.addCase(createUpdateProfilePhoto.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createUpdateProfilePhoto.fulfilled, (state, action) => {
      state.profilePhoto = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createUpdateProfilePhoto.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //User Update Profile
    builder.addCase(updateUserProfileAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateUserProfileAction.fulfilled, (state, action) => {
      state.userUpdated = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateUserProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //update password
    builder.addCase(updateUserPasswordAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(resetPasswordAction, (state, action) => {
      state.isPasswordUpdated = true;
    });
    builder.addCase(updateUserPasswordAction.fulfilled, (state, action) => {
      state.loading = false;
      state.passwordUpdated = action?.payload;
      state.isPasswordUpdated = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(updateUserPasswordAction.rejected, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //Fetch all Trainers
    builder.addCase(fetchAllTrainersAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTrainersAction.fulfilled, (state, action) => {
      state.fetchTrainers = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchAllTrainersAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //Fetch spacifec Trainer
    builder.addCase(fetchTrainerProfileAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTrainerProfileAction.fulfilled, (state, action) => {
      state.trainerDetail = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchTrainerProfileAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //Fetch Client Supplement
    builder.addCase(fetchClientSupplementAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchClientSupplementAction.fulfilled, (state, action) => {
      state.supplementDetail = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchClientSupplementAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //Fetch Client Nutrition
    builder.addCase(fetchClientNutritionAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchClientNutritionAction.fulfilled, (state, action) => {
      state.nutritionDetail = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchClientNutritionAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //remove supplement from client
    builder.addCase(removeSupplementFromUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      removeSupplementFromUserAction.fulfilled,
      (state, action) => {
        state.removeSupplement = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      removeSupplementFromUserAction.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    //add supplement to client
    builder.addCase(addSupplementToUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addSupplementToUserAction.fulfilled, (state, action) => {
      state.addSupplement = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addSupplementToUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //add Workout to client
    //----------------------------------------------------------------
    builder.addCase(addWorkoutToUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addWorkoutToUserAction.fulfilled, (state, action) => {
      state.addworkout = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addWorkoutToUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //remove Workout from client
    //----------------------------------------------------------------
    builder.addCase(removeWorkoutFromUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeWorkoutFromUserAction.fulfilled, (state, action) => {
      state.removeworkout = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(removeWorkoutFromUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //fetch workout with client
    //----------------------------------------------------------------
    builder.addCase(fetchClientWorkoutAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchClientWorkoutAction.fulfilled, (state, action) => {
      state.workoutDetails = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchClientWorkoutAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //----------------------------------------------------------------
    //fetch tools with client
    //----------------------------------------------------------------
    builder.addCase(fetchClientToolAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchClientToolAction.fulfilled, (state, action) => {
      state.toolDetails = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchClientToolAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //add tools to client
    //----------------------------------------------------------------
    builder.addCase(addToolsToUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addToolsToUserAction.fulfilled, (state, action) => {
      state.addTool = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addToolsToUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //remove tools from client
    //----------------------------------------------------------------
    builder.addCase(removeToolFromUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeToolFromUserAction.fulfilled, (state, action) => {
      state.removetool = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(removeToolFromUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //add guideline to client
    //----------------------------------------------------------------
    builder.addCase(addGudelineToUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addGudelineToUserAction.fulfilled, (state, action) => {
      state.addguideline = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addGudelineToUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //remove guideline from client
    //----------------------------------------------------------------
    builder.addCase(removeGudelineFromUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeGudelineFromUserAction.fulfilled, (state, action) => {
      state.removeguideline = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(removeGudelineFromUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    //fetch all guideline with client
    //----------------------------------------------------------------
    builder.addCase(fetchClientGuidelineAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchClientGuidelineAction.fulfilled, (state, action) => {
      state.guidelineDetail = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchClientGuidelineAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //add nutrition to client
    builder.addCase(addNutritionToUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addNutritionToUserAction.fulfilled, (state, action) => {
      state.addNutrition = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(addNutritionToUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //remove nutrition from client
    builder.addCase(removeNutritionFromUserAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      removeNutritionFromUserAction.fulfilled,
      (state, action) => {
        state.removeNutrition = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(removeNutritionFromUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //follow Trainer
    builder.addCase(followTrainerAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(followTrainerAction.fulfilled, (state, action) => {
      state.followTrainer = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(followTrainerAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //unfollow Trainer
    builder.addCase(unfollowTrainerAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(unfollowTrainerAction.fulfilled, (state, action) => {
      state.unFollowTrainer = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(unfollowTrainerAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //logout the user
    builder.addCase(logoutUserAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(logoutUserAction.fulfilled, (state, action) => {
      state.userAuth = undefined;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(logoutUserAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default usersSlices.reducer;
