import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ClientSupplement.scss";
import { fetchAllSupplementPlansWithClient } from "../../../redux/slices/supplement/supplementSlice";
const ClientSupplement = () => {
  const dispatch = useDispatch();

  //select store data
  const supplement = useSelector((state) => state?.supplement);
  const { fetchClientSupplement, isCreated, loading, appErr, serverErr } =
    supplement;
  useEffect(() => {
    dispatch(fetchAllSupplementPlansWithClient(""));
  }, [dispatch]);
  return (
    <div className="app_client-supplement-background-container">
      <div className="app_client-supplement-big-container">
        <h1>Training Supplement Recommendation</h1>
        <div className="app_client-supplement-big-box-center">
          {appErr || serverErr ? (
            <h1>
              {serverErr} {appErr}
            </h1>
          ) : fetchClientSupplement?.length <= 0 ? (
            <h1>There is no Supplement added yet</h1>
          ) : (
            fetchClientSupplement?.map((supplement) => (
              <div
                key={supplement?._id}
                className="app_client-bottom-supplement-item-container"
              >
                <img src={supplement?.image} alt="" />
                <h2> {supplement?.title}</h2>
                <h1>Description</h1>
                <h2> {supplement?.description}</h2>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientSupplement;
