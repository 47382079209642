import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

//nutrition functions
export const createnutritiontemplateAction = createAsyncThunk(
  "nutrition/created",
  async (nutrition, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call
      const formData = new FormData();
      formData.append("templateName", nutrition?.templateName);
      formData.append("dayName", nutrition?.dayName);
      formData.append("dayNotes", nutrition?.dayNotes);
      formData.append("calories", nutrition?.calories);
      formData.append("protein", nutrition?.protein);
      formData.append("carbs", nutrition?.carbs);
      formData.append("fats", nutrition?.fats);

      // Handle the breakfast array
      if (Array.isArray(nutrition?.meals?.breakfast)) {
        nutrition.meals.breakfast.forEach((meal, index) => {
          formData.append(`meals[breakfast][${index}][name]`, meal.name || "");
          formData.append(
            `meals[breakfast][${index}][quantity]`,
            meal.quantity || ""
          );
          formData.append(
            `meals[breakfast][${index}][notes]`,
            meal.notes || ""
          );
        });
      }
      // Handle the snack1 array
      if (Array.isArray(nutrition?.meals?.snack1)) {
        nutrition.meals.snack1.forEach((meal, index) => {
          formData.append(`meals[snack1][${index}][name]`, meal.name || "");
          formData.append(
            `meals[snack1][${index}][quantity]`,
            meal.quantity || ""
          );
          formData.append(`meals[snack1][${index}][notes]`, meal.notes || "");
        });
      }
      // Handle the lunch array
      if (Array.isArray(nutrition?.meals?.lunch)) {
        nutrition.meals.lunch.forEach((meal, index) => {
          formData.append(`meals[lunch][${index}][name]`, meal.name || "");
          formData.append(
            `meals[lunch][${index}][quantity]`,
            meal.quantity || ""
          );
          formData.append(`meals[lunch][${index}][notes]`, meal.notes || "");
        });
      }
      // Handle the snack2 array
      if (Array.isArray(nutrition?.meals?.snack2)) {
        nutrition.meals.snack2.forEach((meal, index) => {
          formData.append(`meals[snack2][${index}][name]`, meal.name || "");
          formData.append(
            `meals[snack2][${index}][quantity]`,
            meal.quantity || ""
          );
          formData.append(`meals[snack2][${index}][notes]`, meal.notes || "");
        });
      }
      // Handle the dinner array
      if (Array.isArray(nutrition?.meals?.dinner)) {
        nutrition.meals.dinner.forEach((meal, index) => {
          formData.append(`meals[dinner][${index}][name]`, meal.name || "");
          formData.append(
            `meals[dinner][${index}][quantity]`,
            meal.quantity || ""
          );
          formData.append(`meals[dinner][${index}][notes]`, meal.notes || "");
        });
      }

      //http call
      const { data } = await axios.post(
        `${baseUrl}/nutritiontemplates`,
        formData,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchNutritionsAction = createAsyncThunk(
  "post/getNutrition",

  async (nutritionid, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/nutritiontemplates/${userAuth._id}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//fetch all nutrition plans with the trainer action
export const fetchAllNutritionPlansWithTrainer = createAsyncThunk(
  "get/nutritionPlans",

  async (nutritionId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/nutritiontemplates/trainer-nutrition`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Fetch All nutrition with Client
//-------------------------------
export const fetchAllNutritionPlansWithClient = createAsyncThunk(
  "get/nutritionwithclient",

  async (nutritionId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/nutritiontemplates/client-nutrition`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------
//Fetch One nutrition with Client
//-------------------------------
export const fetchOneNutritionPlansWithClient = createAsyncThunk(
  "get/onenutritionwithclient",

  async (nutritionId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/nutritiontemplates/client-nutrition-temp/${nutritionId}`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Delete Nutrition From Trainer
//-------------------------------
export const deleteNutritionTemplateFromTrainerAction = createAsyncThunk(
  "delete/nutrition",
  async (nutritionId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call
      const { data } = await axios.delete(
        `${baseUrl}/nutritiontemplates/trainer-nutrition`,
        {
          ...config,
          data: { nutritionId: nutritionId }, // Pass the guideline ID in the request body
        }
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const nutritionSlice = createSlice({
  name: "nutrition",
  initialState: {},
  extraReducers: (builder) => {
    // create post
    builder.addCase(createnutritiontemplateAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      createnutritiontemplateAction.fulfilled,
      (state, action) => {
        state.postLists = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(createnutritiontemplateAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    // fetch all user nutrition templates
    builder.addCase(fetchNutritionsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchNutritionsAction.fulfilled, (state, action) => {
      state.nutritionCreated = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchNutritionsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //fetch all nutrition plans with the trainer
    builder.addCase(
      fetchAllNutritionPlansWithTrainer.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchAllNutritionPlansWithTrainer.fulfilled,
      (state, action) => {
        state.nutritionGet = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchAllNutritionPlansWithTrainer.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    //fetch all nutrition plans with the Client
    builder.addCase(
      fetchAllNutritionPlansWithClient.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchAllNutritionPlansWithClient.fulfilled,
      (state, action) => {
        state.nutritionClient = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchAllNutritionPlansWithClient.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    //fetch One nutrition plans with the Client
    builder.addCase(
      fetchOneNutritionPlansWithClient.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchOneNutritionPlansWithClient.fulfilled,
      (state, action) => {
        state.nutritionselected = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchOneNutritionPlansWithClient.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    builder.addCase(
      deleteNutritionTemplateFromTrainerAction.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      deleteNutritionTemplateFromTrainerAction.fulfilled,
      (state, action) => {
        state.nutritionDelete = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      deleteNutritionTemplateFromTrainerAction.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
  },
});
export default nutritionSlice.reducer;
