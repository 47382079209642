import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

//----------------------------------------------------------------
// Create new Tool
//----------------------------------------------------------------

export const createToolAction = createAsyncThunk(
  "tool/created",
  async (tool, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const formData = new FormData();
      formData.append("title", tool?.title);
      formData.append("description", tool?.description);
      formData.append("image", tool?.image);

      //http call

      const { data } = await axios.post(`${baseUrl}/tool`, formData, config);

      return data;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);

//----------------------------------------------------------------
// Fetch all Tools With The Trainer
//----------------------------------------------------------------

export const fetchToolsAction = createAsyncThunk(
  "get/getTools",

  async (toolId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(`${baseUrl}/tool/trainer-tool`, config);

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Fetch All Tools with Client
//-------------------------------
export const fetchAllToolsPlansWithClient = createAsyncThunk(
  "get/toolPlansWithClient",

  async (toolId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(`${baseUrl}/tool/client-tool`, config);

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Delete Tool From Trainer
//-------------------------------
export const deleteToolTemplateFromTrainerAction = createAsyncThunk(
  "delete/toolPlan",
  async (toolId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call
      const { data } = await axios.delete(`${baseUrl}/tool/trainer-tool`, {
        ...config,
        data: { toolId: toolId }, // Pass the guideline ID in the request body
      });

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const toolSlice = createSlice({
  name: "gettool",
  initialState: {},
  extraReducers: (builder) => {
    //----------------------------------------------------------------
    // Create Tools
    //----------------------------------------------------------------
    builder.addCase(createToolAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createToolAction.fulfilled, (state, action) => {
      state.toolCreated = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createToolAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    // Fetch all Tools With The Trainer
    //----------------------------------------------------------------
    builder.addCase(fetchToolsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchToolsAction.fulfilled, (state, action) => {
      state.getTool = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchToolsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    // Fetch all Tools With The Client
    //----------------------------------------------------------------
    builder.addCase(fetchAllToolsPlansWithClient.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllToolsPlansWithClient.fulfilled, (state, action) => {
      state.clientTools = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchAllToolsPlansWithClient.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });
    //----------------------------------------------------------------
    // Delete Tools From The Trainer
    //----------------------------------------------------------------
    builder.addCase(
      deleteToolTemplateFromTrainerAction.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      deleteToolTemplateFromTrainerAction.fulfilled,
      (state, action) => {
        state.deleteTool = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      deleteToolTemplateFromTrainerAction.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
  },
});
export default toolSlice.reducer;
