import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addWorkoutToUserAction,
  removeWorkoutFromUserAction,
} from "../../../redux/slices/users/usersSlices";
import { fetchWorkoutsAction } from "../../../redux/slices/workout/workoutSlices";
import { useDispatch, useSelector } from "react-redux";
import DateFormatter from "../../../utils/DateFormatter";

const ClientProfilePageWorkout = () => {
  // Initialize selectedSupplementId with an empty string initially
  const [selectedWorkoutId, setSelectedWorkoutId] = useState("");

  const handleGuidelineSelection = (event) => {
    setSelectedWorkoutId(event.target.value);
  };

  const { id } = useParams();

  function capitalizeWord(word) {
    if (typeof word === "string" && word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    // Return an empty string or handle the case when word is undefined or empty
    return ""; // You can return an empty string or any other value as needed
  }
  const dispatch = useDispatch();

  //User data from store
  const users = useSelector((state) => state?.users);
  const { workoutDetails } = users;

  // Assuming you have a list of available supplements in your Redux state
  const getWorkout = useSelector((state) => state?.workout);
  const { fetchWorkout } = getWorkout;

  // Fetch supplement data when the component mounts
  useEffect(() => {
    dispatch(fetchWorkoutsAction());
  }, [dispatch]);

  // Update selectedSupplementId when supplementCreated changes
  useEffect(() => {
    // Check if supplementCreated is not empty and update selectedSupplementId with the first supplement's ID
    if (fetchWorkout?.length > 0 && !selectedWorkoutId) {
      setSelectedWorkoutId(fetchWorkout[0]?.id || "");
    }
  }, [fetchWorkout, selectedWorkoutId]);
  const handleAddSupplement = () => {
    if (selectedWorkoutId) {
      dispatch(
        addWorkoutToUserAction({
          clientId: id,
          workoutId: selectedWorkoutId,
        })
      );
    }
  };
  console.log(fetchWorkout);
  return (
    <div>
      <div className="flex justify-center place-items-start flex-wrap  md:mb-0">
        {/* All my Post */}
        <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0 text-white">
          <h1 className="text-center text-xl border-gray-500 mb-2 border-b-2">
            Workout Plans
          </h1>

          {/* Loo here */}
          {workoutDetails?.workout?.length <= 0 ? (
            <h2 className="text-center text-xl"> No Workout plans Added Yet</h2>
          ) : (
            workoutDetails?.workout?.map((workout) => (
              <div className="flex justify-center align-middle flex-wrap  -mx-3 mt-3   lg:mb-6">
                <div className="w-full lg:w-3/4 px-3">
                  <button
                    onClick={() =>
                      dispatch(
                        removeWorkoutFromUserAction({
                          clientId: id,
                          workoutId: workout?.id,
                        })
                      )
                    }
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    Remove
                  </button>
                  <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                    {capitalizeWord(workout?.workoutTemplate?.overallGoal)}
                  </h3>

                  <p className="text-white ">
                    {workout?.workoutTemplate?.workoutFocus}
                  </p>
                  <p className="m-3 text-black bg-white font-bold text-lg">
                    Created At:{" "}
                    <DateFormatter date={workout?.workoutTemplate?.createdAt} />
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="flex justify-center align-middle items-center mb-4">
        <select
          className="p-2 border text-black border-gray-300 rounded-md mr-2"
          onChange={handleGuidelineSelection}
          value={selectedWorkoutId}
        >
          {fetchWorkout?.map((workout) => (
            <option key={workout.id} value={workout.id}>
              {workout.workoutTemplate?.overallGoal}
            </option>
          ))}
        </select>
        <button
          onClick={handleAddSupplement}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add Workout
        </button>
      </div>
    </div>
  );
};

export default ClientProfilePageWorkout;
