import "./App.scss";
import { useSelector } from "react-redux";

// import Navbar from "./components/Navs/Navbar/Navbar";
import Navbar from "./components/Navs/NavbarC.jsx";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import NutritionPage from "./pages/Nutrition/NutritionPage";
import WorkoutPage from "./pages/Workout/WorkoutPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import Footer from "./components/Footer/Footer";
import Agreements from "./pages/Agreements/Agreements";
import Register from "./components/Users/Register/Register";
import Login from "./components/Users/Login/Login";
import TrainerProfile from "./pages/TrainerPages/TrainerProfile/TrainerProfile.jsx";
import NutritionTemplate from "./pages/TrainerPages/NutritionTemplates/NutritionTemplate.jsx";
import TrainerProtectedRoute from "./components/Navs/ProtectedRoutes/PrivateProtectRoute.js";
import SupplementTemplates from "./pages/TrainerPages/SupplementTemplates/SupplementTemplates.jsx";
import WorkoutTemp from "./pages/TrainerPages/WorkoutTemplates/WorkoutTemp.jsx";
import TrainerUploadProfilePic from "./pages/TrainerPages/TrainerProfile/TrainerUploadProfilePic.jsx";
import TrainerUpdateProfile from "./pages/TrainerPages/TrainerProfile/TrainerUpdateProfile.jsx";
import ClientProtectedRoute from "./components/Navs/ProtectedRoutes/ClientProtectRoute.js";
import CoachPage from "./pages/ClientPages/CoachesPage/CoachPage.jsx";
import CoachDetails from "./pages/ClientPages/CoachesPage/CoachDetails.jsx";
import ClientProfilePage from "./pages/TrainerPages/ClientProfilePage/ClientProfilePage.jsx";
import AchievmentsPage from "./pages/TrainerPages/AchievmentsPage/AchievmentsPage.jsx";
import TrainingGuidLine from "./pages/TrainerPages/TrainingGuidLine/TrainingGuidLine.jsx";
import TrainingTools from "./pages/TrainerPages/TrainingTools/TrainingTools.jsx";
import ClientTrainingGuideLine from "./pages/ClientPages/ClientTrainingGuideLine/ClientTrainingGuideLine.jsx";
import ClientWorkout from "./pages/ClientPages/ClientWorkout/ClientWorkout.jsx";
import ClientNutrition from "./pages/ClientPages/ClientNutrition/ClientNutrition.jsx";
import ClientSupplement from "./pages/ClientPages/ClientSupplement/ClientSupplement.jsx";
import ClientTools from "./pages/ClientPages/ClientTools/ClientTools.jsx";
import ClientProfile from "./pages/ClientPages/ClientProfile/ClientProfile.jsx";
import ChangePassword from "./pages/ClientPages/ClientProfile/ChangePassword.jsx";
import ResetPasswordForm from "./pages/ClientPages/ClientProfile/ResetPasswordForm.jsx";
import ResetPassword from "./pages/ClientPages/ClientProfile/ResetPassword.jsx";
import ClientNutritionfirstpage from "./pages/ClientPages/ClientNutrition/ClientNutritionfirstpage.jsx";
import ClientOneNutrition from "./pages/ClientPages/ClientNutrition/ClientOneNutrition.jsx";
import ClientWorkoutFirstPage from "./pages/ClientPages/ClientWorkout/ClientWorkoutFirstPage.jsx";
import ClientOneWorkout from "./pages/ClientPages/ClientWorkout/ClientOneWorkout.jsx";

function App() {
  const state = useSelector((state) => state.users);
  const { userAuth } = state;
  const userId = userAuth ? userAuth._id : null;
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/nutrition" element={<NutritionPage />} />
          <Route exact path="/workout" element={<WorkoutPage />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/password-reset-token"
            element={<ResetPasswordForm />}
          />
          <Route exact path="/reset-password/:id" element={<ResetPassword />} />

          <Route exact path="/register" element={<Register />} />
          <Route exact path="/agreement" element={<Agreements />} />
          <Route exact path="*" element={<NotFoundPage />} />
          {/* Trainer Routes */}
          <Route exact path="/" element={<TrainerProtectedRoute />}>
            <Route
              exact
              path={`/trainer-profile/${userId}`}
              element={<TrainerProfile />}
            />
            <Route
              exact
              path={`/trainer-update-profile/${userId}`}
              element={<TrainerUpdateProfile />}
            />
            <Route
              exact
              path={`/trainer-new-achievement`}
              element={<AchievmentsPage />}
            />
            <Route
              exact
              path={`/trainer-profile-profile-photo/${userId}`}
              element={<TrainerUploadProfilePic />}
            />
            <Route
              exact
              path="/nutrition-templates"
              element={<NutritionTemplate />}
            />
            <Route
              exact
              path="/training-guidline-templates"
              element={<TrainingGuidLine />}
            />
            <Route
              exact
              path="/training-tools-templates"
              element={<TrainingTools />}
            />
            <Route
              exact
              path="/supplement-templates"
              element={<SupplementTemplates />}
            />
            <Route
              exact
              path="/client-profile/:id"
              element={<ClientProfilePage />}
            />
            <Route
              exact
              path={`/trainer/change-password`}
              element={<ChangePassword />}
            />
            <Route exact path="/workout-templates" element={<WorkoutTemp />} />
          </Route>
          <Route exact path="/" element={<ClientProtectedRoute />}>
            <Route exact path={`/find-mycoach`} element={<CoachPage />} />
            <Route
              exact
              path={`/training-guideline`}
              element={<ClientTrainingGuideLine />}
            />
            <Route
              exact
              path={`/training-workout`}
              element={<ClientWorkoutFirstPage />}
            />
            <Route
              exact
              path={`/workout-template/:id`}
              element={<ClientOneWorkout />}
            />
            <Route
              exact
              path={`/training-nutrition`}
              element={<ClientNutritionfirstpage />}
            />
            <Route
              exact
              path={`/nutrition-template/:id`}
              element={<ClientOneNutrition />}
            />
            <Route
              exact
              path={`/training-supplement`}
              element={<ClientSupplement />}
            />
            <Route exact path={`/training-tools`} element={<ClientTools />} />
            <Route exact path={`/profile/:id`} element={<ClientProfile />} />
            <Route
              exact
              path={`/change-password`}
              element={<ChangePassword />}
            />
            <Route
              exact
              path={`/trainer-profile-details/:id`}
              element={<CoachDetails />}
            />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
