import React from "react";
import "./NotFoundPage.scss";
import notFoundLogo from "../../assets/image/notfound.png";
import CptLogo from "../../assets/image/cpt-logo.png";
import Elite from "../../assets/image/elite.png";
const NotFoundPage = () => {
  return (
    <div className="app_notfound-page-container">
      <div className="app_notfound-container">
        <div className="app_notfound-container-img">
          <img src={notFoundLogo} alt="" srcset="" />
        </div>
        <div className="app_notfound-container-text">
          <div className="app_notfound-text-404">404</div>
          <div className="app_notfound-container-text-up">
            Lost in the digital jungle? Fear not, we're here to help you find
            your way back. Let's navigate together!
          </div>
          <div className="app_notfound-container-text-down">
            <div className="app_notfound-container-text-up2">
              <a href="/">
                <button className="app__profile-resume-btn-1">Go Home</button>
              </a>
            </div>
            <div className="app_notfound-container-text-down2">
              <img
                className="app__profile-sig-1"
                src={CptLogo}
                alt="Certified Certificate Personal Trainer"
              />
              <img
                className="app__profile-sig-1"
                src={Elite}
                alt="Certified Certificate Elite Trainer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
