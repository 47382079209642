import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ClientTools.scss";
import { fetchAllToolsPlansWithClient } from "../../../redux/slices/tool/toolSlice";
const ClientTools = () => {
  const dispatch = useDispatch();

  //select store data
  const tool = useSelector((state) => state?.tool);
  const { clientTools, isCreated, loading, appErr, serverErr } = tool;
  useEffect(() => {
    dispatch(fetchAllToolsPlansWithClient(""));
  }, [dispatch]);
  return (
    <div className="app_client-supplement-background-container">
      <div className="app_client-supplement-big-container">
        <h1>Training Tools & Gadgets Recommendation</h1>
        <div className="app_client-supplement-big-box-center">
          {appErr || serverErr ? (
            <h1>
              {serverErr} {appErr}
            </h1>
          ) : clientTools?.length <= 0 ? (
            <h1>There is no Tools & Gadgets added yet</h1>
          ) : (
            clientTools?.map((tool) => (
              <div
                key={tool?._id}
                className="app_client-bottom-supplement-item-container"
              >
                <img src={tool?.image} alt="" />
                <h2> {tool?.title}</h2>
                <h1>Description</h1>
                <h2> {tool?.description}</h2>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientTools;
