import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllWorkoutPlansWithClient,
  fetchOneWorkoutPlansWithClient,
} from "../../../redux/slices/workout/workoutSlices";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
const ClientOneWorkout = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  //select store data
  const workout = useSelector((state) => state?.workout);
  const {
    workoutClient,
    workoutSelected,
    isCreated,
    loading,
    appErr,
    serverErr,
  } = workout;
  useEffect(() => {
    dispatch(fetchAllWorkoutPlansWithClient(""));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchOneWorkoutPlansWithClient(id));
  }, [dispatch]);
  return (
    <div className="app_client-workout-background-container">
      <div className="app_client-workout-big-container">
        <h1>Workout Plans</h1>
        <div className="app_client-workout-big-box-center">
          {appErr || serverErr ? (
            <h1>
              {serverErr} {appErr}
            </h1>
          ) : workoutClient?.length <= 0 ? (
            <h1>There is no Workout added yet</h1>
          ) : (
            <div className="app_client-bottom-workout-item-container">
              <h2> {workoutSelected?.workoutTemplate?.workoutFocus}</h2>
              <h2> Notes: {workoutSelected?.notes}</h2>

              <h1>Warm Up</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400">
                    <tr>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Exercise Name
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Sets
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Reps
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Tempo
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Load
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Weight
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Rest
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Video
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Image
                      </th>
                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {workoutSelected?.workoutTemplate?.workouts?.warmUp?.map(
                      (exercise, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.exerciseName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.sets}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.reps}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.tempo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.load}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.weight}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.rest}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.notes}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            <a
                              href={exercise?.youtubeLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Click Me
                            </a>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {exercise?.imageLink && (
                              <a
                                href={exercise?.imageLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={exercise?.imageLink}
                                  alt="Exercise"
                                  className="h-20 w-20 object-cover rounded-full"
                                />
                              </a>
                            )}
                          </td>
                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <h1>Resistance</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400">
                    <tr>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Exercise Name
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Sets
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Reps
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Tempo
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Load
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Weight
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Rest
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Video
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Image
                      </th>
                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {workoutSelected?.workoutTemplate?.workouts?.resistance?.map(
                      (exercise, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.exerciseName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.sets}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.reps}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.tempo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.load}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.weight}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.rest}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.notes}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            <a
                              href={exercise?.youtubeLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Click Me
                            </a>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {exercise?.imageLink && (
                              <a
                                href={exercise?.imageLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={exercise?.imageLink}
                                  alt="Exercise"
                                  className="h-20 w-20 object-cover rounded-full"
                                />
                              </a>
                            )}
                          </td>
                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <h1>Cardio</h1>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-bordered">
                  <thead className="bg-gray-400">
                    <tr>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Exercise Name
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Sets
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Reps
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Tempo
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Load
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Weight
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Rest
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Notes
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Video
                      </th>
                      <th className="px-6 py-3 text-left text-lg font-extrabold text-black uppercase tracking-wider">
                        Image
                      </th>
                      {/* Add other table headers with similar styling */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {workoutSelected?.workoutTemplate?.workouts?.cardio?.map(
                      (exercise, exIndex) => (
                        <tr key={exIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.exerciseName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.sets}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.reps}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.tempo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.load}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.weight}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.rest}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            {exercise?.notes}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-lg font-extrabold text-black">
                            <a
                              href={exercise?.youtubeLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Click Me
                            </a>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {exercise?.imageLink && (
                              <a
                                href={exercise?.imageLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={exercise?.imageLink}
                                  alt="Exercise"
                                  className="h-20 w-20 object-cover rounded-full"
                                />
                              </a>
                            )}
                          </td>
                          {/* Add other table data with similar styling */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientOneWorkout;
