import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllWorkoutPlansWithClient } from "../../../redux/slices/workout/workoutSlices";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ClientWorkoutFirstPage.scss";
const ClientWorkoutFirstPage = () => {
  const dispatch = useDispatch();
  //select store data
  const workout = useSelector((state) => state?.workout);
  const { workoutClient, isCreated, loading, appErr, serverErr } = workout;

  useEffect(() => {
    dispatch(fetchAllWorkoutPlansWithClient(""));
  }, [dispatch]);

  return (
    <div className="app_workout-first-page-container">
      <div class="  container">
        <div class=" row">
          <div class="app_workout-first-page-midcontainer ">
            {appErr || serverErr ? (
              <h1>
                {serverErr} {appErr}
              </h1>
            ) : workoutClient?.length <= 0 ? (
              <h1>There is no Workout added yet</h1>
            ) : (
              workoutClient?.map((workout, index) => (
                <div key={index} class="serviceBox">
                  <h3 class="title">
                    {workout?.workoutTemplate?.workoutFocus}
                  </h3>

                  <span>
                    <a href={`/workout-template/${workout?._id}`}>Read More</a>
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientWorkoutFirstPage;
