import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";

import "./Offer.scss";
const Offer = () => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const query = '*[_type == "offers"]';

    client.fetch(query).then((data) => {
      setOffers(data);
    });
  }, []);
  return (
    <div>
      <div className="app__offers">
        {offers.map((offer, index) => (
          <div key={offer.title + index}>
            <img
              className="app__offers-image"
              src={urlFor(offer.imgUrl)}
              alt={offer.title}
            />
            <div className="app__offers-about-container">
              <h2 className="app__offers-title" style={{ marginTop: 20 }}>
                {offer.title}
              </h2>
              <p className="app__offers-desc" style={{ marginTop: 10 }}>
                {offer.description}
              </p>
              <a href={`${offer.btnLink}`}>
                <button className="app__profile-resume-btn">
                  {offer.btnType}
                </button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offer;
