import React from "react";

import ClientNavbar from "./Client/ClientNavbar";
import NavbarPublic from "./Navbar/Navbar";
import TrainerNavbar from "./Trainer/TrainerNavbar";
import { useSelector } from "react-redux";

const Navbar = () => {
  //get user from store
  const state = useSelector((state) => state.users);
  const { userAuth } = state;
  const isAdmin = userAuth?.isAdmin;
  const isTrainer = userAuth?.isTrainer;

  return (
    <>
      {isTrainer ? (
        <TrainerNavbar />
      ) : userAuth ? (
        <ClientNavbar />
      ) : (
        <NavbarPublic />
      )}
    </>
  );
};

export default Navbar;
