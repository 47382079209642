import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";
import "./Prices.scss";

const Prices = () => {
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const query = '*[_type == "prices"]';

    client.fetch(query).then((data) => {
      setPrices(data);
    });
  }, []);
  return (
    <div>
      {" "}
      <h2 className="head-text App__profile-header" id="prices">
        Prices <span>Plan</span> <br />
      </h2>
      <div className="app__prices">
        {prices.map((price, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5, type: "tween" }}
            className="app__prices-items2"
            key={price.title + index}
          >
            <img src={urlFor(price.imgUrl)} alt={price.title} />
            <div className="app__prices-about-container">
              <h2 className="app__prices-title" style={{ marginTop: 20 }}>
                {price.title}
              </h2>
            </div>
            <div className="app__prices-div" style={{ marginTop: 20 }}>
              {" "}
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.platform}</p>
                <span className="app__price-sp">{price.platformInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.nutrition}</p>
                <span className="app__price-sp">{price.nutritionInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.workout}</p>
                <span className="app__price-sp">{price.workoutInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.guideLine}</p>
                <span className="app__price-sp">{price.guideLineInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.measurement}</p>
                <span className="app__price-sp">{price.measurementInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.monitoring}</p>
                <span className="app__price-sp">{price.monitoringInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.timeManager}</p>
                <span className="app__price-sp">{price.timeManagerInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.whatsapp}</p>
                <span className="app__price-sp">{price.whatsappInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.mobile}</p>
                <span className="app__price-sp">{price.mobileInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.zoom}</p>
                <span className="app__price-sp">{price.zoomInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.supplement}</p>
                <span className="app__price-sp">{price.supplementInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.tools}</p>
                <span className="app__price-sp">{price.toolsInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.session}</p>
                <span className="app__price-sp">{price.sessionInfo}</span>
              </div>
              <div className="app__prices-item">
                <p className="app__prices-desc">{price.price}</p>
                <span className="app__price-sp">{price.priceInfo}</span>
              </div>
            </div>
            <div className="app__profile-resume-container-1">
              <a href="/agreement">
                <button className="app__profile-resume-btn-2">ابدأ الأن</button>
              </a>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Prices;
