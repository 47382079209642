import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../utils/baseURL";

//Gudeline Functions
//-------------------------------
//GuideLine Create Function
//-------------------------------
export const createGuideLineAction = createAsyncThunk(
  "guideliness/created",
  async (guideline, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const data = {
      templateName: guideline?.templateName,
      title: guideline?.title,
      description: guideline?.description,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call

      const response = await axios.post(`${baseUrl}/guideline`, data, config);

      return response;
    } catch (error) {
      if (!error?.response) throw error;

      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------
//Fetch All Guidelines with Trainer
//-------------------------------
export const fetchAllGuideLinesPlansWithTrainer = createAsyncThunk(
  "get/guidelinePlan",

  async (guidelineId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/guideline/trainer-guidelines`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//-------------------------------
//Fetch All Guidelines with Client
//-------------------------------
export const fetchAllGuideLinesPlansWithClient = createAsyncThunk(
  "get/guidelinePlanClient",

  async (guidelineId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };
    try {
      //http call
      const { data } = await axios.get(
        `${baseUrl}/guideline/client-guidelines`,
        config
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//-------------------------------
//Delete Guidelines From Trainer
//-------------------------------
export const deleteGuidelineTemplateFromTrainerAction = createAsyncThunk(
  "delete/guidelinePlan",
  async (guidelineId, { rejectWithValue, getState, dispatch }) => {
    //get user token
    const user = getState()?.users;
    const { userAuth } = user;
    const config = {
      headers: {
        Authorization: `Bearer ${userAuth?.token}`,
      },
    };

    try {
      //http call
      const { data } = await axios.delete(
        `${baseUrl}/guideline/trainer-guidelines`,
        {
          ...config,
          data: { guidlineId: guidelineId }, // Pass the guideline ID in the request body
        }
      );

      return data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const guidelLineSlice = createSlice({
  name: "createGuideline",
  initialState: {},
  extraReducers: (builder) => {
    //-------------------------------
    // create post
    //-------------------------------
    builder.addCase(createGuideLineAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createGuideLineAction.fulfilled, (state, action) => {
      state.guideLineCreated = action?.payload;
      state.loading = false;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createGuideLineAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload?.message;
      state.serverErr = action?.error?.message;
    });

    //-------------------------------
    // get all guideline for specific trainer
    //-------------------------------
    builder.addCase(
      fetchAllGuideLinesPlansWithTrainer.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchAllGuideLinesPlansWithTrainer.fulfilled,
      (state, action) => {
        state.fetchguideline = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchAllGuideLinesPlansWithTrainer.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
    //-------------------------------
    // get all guideline for specific Client
    //-------------------------------
    builder.addCase(
      fetchAllGuideLinesPlansWithClient.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchAllGuideLinesPlansWithClient.fulfilled,
      (state, action) => {
        state.fetchguideline = action?.payload;
        state.loading = false;
        state.appErr = undefined;
        state.serverErr = undefined;
      }
    );
    builder.addCase(
      fetchAllGuideLinesPlansWithClient.rejected,
      (state, action) => {
        state.loading = false;
        state.appErr = action?.payload?.message;
        state.serverErr = action?.error?.message;
      }
    );
  },
});
export default guidelLineSlice.reducer;
