import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import "./TrainingTools.scss";
import {
  createToolAction,
  deleteToolTemplateFromTrainerAction,
  fetchToolsAction,
} from "../../../redux/slices/tool/toolSlice";
import DateFormatter from "../../../utils/DateFormatter";

const formSchema = Yup.object({
  title: Yup.string().required("Tool title is required"),
  description: Yup.string().required("Tool description is required"),
  image: Yup.string().required("Tool image is required"),
});

//css for dropzone
const Container = styled.div`
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px;
border-width: 2px;
border-radius: 2px;
border-style: dashed;
background-color: #fafafa;
color: #bdbdbd;
border-color:'red'
transition: border 0.24s ease-in-out;
`;

const TrainingTools = () => {
  const dispatch = useDispatch();
  //select store data
  const tool = useSelector((state) => state?.tool);
  const { getTool, loading, appErr, serverErr } = tool;

  //formik
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",
    },

    onSubmit: (values) => {
      //dispath the action

      const data = {
        title: values?.title,
        description: values?.description,
        image: values?.image,
      };

      dispatch(createToolAction(data));
      //fetch posts
    },
    validationSchema: formSchema,
  });
  useEffect(() => {
    dispatch(fetchToolsAction(""));
  }, [dispatch]);
  return (
    <div className="app_tools-background-trainer-big-container">
      <div className="app_tools-trainer-big-container">
        <div className="app_tools-trainer-up-big-container">
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 py-3 px-3"
              >
                Title
              </label>
              <div className="mt-1">
                {/* Title */}
                <input
                  value={formik.values.title}
                  onChange={formik.handleChange("title")}
                  onBlur={formik.handleBlur("title")}
                  id="title"
                  name="title"
                  type="title"
                  autoComplete="title"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* Err msg */}
              <div className="text-red-500 px-3" dir="rtl">
                {formik?.touched?.title && formik?.errors?.title}
              </div>
            </div>
            {/* Category input goes here */}

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 px-3"
              >
                Description
              </label>
              {/* Description */}
              <textarea
                value={formik.values.description}
                onChange={formik.handleChange("description")}
                onBlur={formik.handleBlur("description")}
                rows="5"
                cols="10"
                className="rounded-lg appearance-none block w-full py-3 px-3 text-base text-center leading-tight text-gray-600   border border-gray-200 focus:border-gray-500  focus:outline-none"
                type="text"
              ></textarea>
              {/* Err msg */}
              <div className="text-red-500 px-3" dir="rtl">
                {formik?.touched?.description && formik.errors?.description}
              </div>
              {/* Image component */}
              <label
                htmlFor="password"
                className="block text-sm font-medium mt-3 mb-2 text-gray-700 px-3"
              >
                Select image to upload
              </label>

              <Container className="container bg-gray-700">
                <Dropzone
                  onBlur={formik.handleBlur("image")}
                  accept="image/jpeg, image/png"
                  onDrop={(acceptedFiles) => {
                    formik.setFieldValue("image", acceptedFiles[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="container">
                      <div
                        {...getRootProps({
                          className: "dropzone",
                          onDrop: (event) => event.stopPropagation(),
                        })}
                      >
                        <input {...getInputProps()} />

                        <p className="text-gray-300 text-lg cursor-pointer hover:text-gray-500 px-3">
                          Click here to select image
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Container>
            </div>
            <div>
              {/* Submit btn */}
              {loading ? (
                <button
                  disabled
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Loading please wait...
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="app_tools-bottom-big-container">
          <div className="app_tools-bottom-small-container">
            {appErr || serverErr ? (
              <h1>
                {serverErr} {appErr}
              </h1>
            ) : getTool?.length <= 0 ? (
              <h1>There is no GuideLines added yet</h1>
            ) : (
              getTool?.map((tool) => (
                <div className="app_tools-bottom-item-container">
                  <button
                    onClick={() =>
                      dispatch(
                        deleteToolTemplateFromTrainerAction({
                          toolId: tool._id,
                        })
                      )
                    }
                  >
                    Delete
                  </button>
                  <img src={tool?.image} alt="" />
                  <h1>{tool?.title}</h1>

                  <h3>{tool?.description}</h3>

                  <div className="flex space-x-1 text-sm text-black-500">
                    <time>
                      <DateFormatter date={tool?.createdAt} />
                    </time>
                    <span aria-hidden="true">&middot;</span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingTools;
