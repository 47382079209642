import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { client } from "../../client";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(65, 38, 0, 0.5)",
  direction: "rtl",
  fontSize: 25,
  flexDirection: "row-reverse",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "5px solid rgba(0, 0, 0, .125)",
  direction: "rtl",
  fontSize: 45,
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: isExpanded ? true : false,
    }));
  };

  const [accordions, setAccordions] = React.useState([]);

  React.useEffect(() => {
    const query = '*[_type == "accordion"]';
    client.fetch(query).then((data) => {
      // Sort the accordions by dateTime in ascending order
      const sortedAccordions = data.sort((a, b) => {
        return new Date(a.dateTime) - new Date(b.dateTime);
      });
      setAccordions(sortedAccordions);
    });
  }, []);

  return (
    <div>
      <h2 className="head-text App__profile-header" id="prices">
        سياسات الموقع <br />
      </h2>
      {accordions.map((accordion, index) => (
        <Accordion
          key={accordion.title + index}
          expanded={expanded[`panel${index + 1}`]}
          onChange={handleChange(`panel${index + 1}`)}
        >
          <AccordionSummary
            aria-controls={`panel${index + 1}d-content`}
            id={`panel${index + 1}d-header`}
          >
            <Typography
              style={{
                fontSize: 20,
                fontWeight: 800,
                fontFamily: "El Messiri",
              }}
            >
              {accordion.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontSize: 20,
                fontWeight: 600,
                fontFamily: "El Messiri",
              }}
            >
              {accordion.desc}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
