import React, { useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import logo from "../../../assets/image/Logo.png";
import "./Navbar.scss";

const NavbarPublic = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        <li className="app__flex app__navbar-links2 p-text">
          <Link className="app__navbar-links-btns" to="/">
            HOME
          </Link>
          <Link className="app__navbar-links-btns" to="/nutrition">
            NUTRITION
          </Link>
          <Link className="app__navbar-links-btns" to="/workout">
            WORKOUT
          </Link>

          {/* <a
            className="app__navbar-links-btns"
            href="https://workout.rahmou.com/"
          >
            {" "}
            EXERCISE
          </a> */}
        </li>
      </ul>
      <div>
        {" "}
        <a href="/login">
          <button className="app__profile-resume-btn-1">Login</button>
        </a>
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links-ul">
              <li>
                <Link
                  className="app__navbar-links-btns1"
                  to="/"
                  onClick={() => setToggle(false)}
                >
                  HOME
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/nutrition"
                  onClick={() => setToggle(false)}
                >
                  NUTRITION
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/workout"
                  onClick={() => setToggle(false)}
                >
                  WORKOUT
                </Link>
                {/* <a
                  className="app__navbar-links-btns1"
                  onClick={() => setToggle(false)}
                  href="https://workout.rahmou.com/"
                >
                  EXERCISE
                </a> */}
                <Link
                  className="app__navbar-links-btns1"
                  to="/login"
                  onClick={() => setToggle(false)}
                >
                  Login
                </Link>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default NavbarPublic;
