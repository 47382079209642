import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "../slices/users/usersSlices";
import nutrition from "../slices/nutrition/nutritionSlices";
import supplement from "../slices/supplement/supplementSlice";
import workout from "../slices/workout/workoutSlices";
import post from "../slices/post/postSlices";
import guideline from "../slices/guideline/guidelineSlice";
import tool from "../slices/tool/toolSlice";

const store = configureStore({
  reducer: {
    users: usersReducer,
    nutrition,
    supplement,
    workout,
    post,
    guideline,
    tool,
  },
});
export default store;
