import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import React, { useState, useEffect } from "react";
import "./NutritionExamples.scss";
const NutritionExamples = () => {
  const [nutritionExamples, setWorks] = useState([]);

  useEffect(() => {
    const query = '*[_type == "nutritionExample"]';

    client.fetch(query).then((data) => {
      setWorks(data);
    });
  }, []);

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  return (
    <>
      <div className="app_nutrition-examples-container">
        <div className="app_nutrition-examples-container-top-title">
          Diet Plan Examples
        </div>

        <div className="app_nutrition-examples-container-top-note">
          You Can Download Any Of This Plans By Just Pressing On The Image
        </div>
        <div className="app__nutrition-example-container-examples">
          {nutritionExamples.map((nutritionExample, index) => (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1.5, type: "tween" }}
              className="app__nutrition-example-items"
              key={nutritionExample.calories + index}
            >
              <img
                src={urlFor(nutritionExample.imgUrl)}
                alt={nutritionExample.calories}
                onClick={() =>
                  openImageInNewTab(urlFor(nutritionExample.imgUrl))
                }
              />
              <div className="app__nutrition-example-items-title">
                <p
                  className="app__nutrition-example-items-text"
                  style={{ marginTop: 10 }}
                >
                  {nutritionExample.calories} Calories
                </p>
                <p
                  className="app__nutrition-example-items-text"
                  style={{ marginTop: 10 }}
                >
                  {nutritionExample.meals} Meals
                </p>

                <p
                  className="app__nutrition-example-items-text"
                  style={{ marginTop: 10 }}
                >
                  Proteins: {nutritionExample.proteins} (g)
                </p>
                <p
                  className="app__nutrition-example-items-text"
                  style={{ marginTop: 10 }}
                >
                  Carbohydrates: {nutritionExample.carbs} (g)
                </p>
                <p
                  className="app__nutrition-example-items-text"
                  style={{ marginTop: 10 }}
                >
                  Fats: {nutritionExample.fats} (g)
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NutritionExamples;
