import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DateFormatter from "../../../utils/DateFormatter";
import "./ClientTrainingGuideLine.scss";
import { fetchAllGuideLinesPlansWithClient } from "../../../redux/slices/guideline/guidelineSlice";
const ClientTrainingGuideLine = () => {
  const dispatch = useDispatch();

  //select store data
  const guideline = useSelector((state) => state?.guideline);
  const { fetchguideline, isCreated, loading, appErr, serverErr } = guideline;

  useEffect(() => {
    dispatch(fetchAllGuideLinesPlansWithClient(""));
  }, [dispatch]);
  return (
    <div className="app_client-guideline-background-container">
      <div className="app_client-guideline-big-container">
        <h1>Training GuideLines</h1>
        <div className="app_client-guideline-big-box-center">
          {appErr || serverErr ? (
            <h1>
              {serverErr} {appErr}
            </h1>
          ) : fetchguideline?.length <= 0 ? (
            <h1>There is no GuideLines added yet</h1>
          ) : (
            fetchguideline?.map((guideline) => (
              <div
                key={guideline._id}
                className="app_client-bottom-item-container"
              >
                <h2> {guideline?.title}</h2>
                <h1>Description</h1>
                <h2> {guideline?.description}</h2>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientTrainingGuideLine;
