import React, { useState } from "react";
import "./Agreements.scss";
import { useNavigate } from "react-router-dom";
import CustomizedAccordions from "../../components/Accordion/Accordion";
import { client } from "../../client";

const Agreements = ({ history }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    town: "",
    whatsapp: "",
    firstQ: "",
    secondQ: "",
    thirdQ: "",
    forthQ: "",
    fifthQ: "",
    sixthQ: "",
    seventhQ: "",
    eightthQ: "",
    ninthQ: "",
    tenthQ: "",
    eleventhQ: "",
    twelveQ: "",
    thirteenQ: "",
    forteenQ: "",
    fifteenQ: "",
  });
  const { username, age } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = () => {
    const contact = {
      _type: "agreementSubmit",
      name: formData.name,
      age: formData.age,
      town: formData.town,
      whatsapp: formData.whatsapp,
      firstQ: formData.firstQ,
      secondQ: formData.secondQ,
      thirdQ: formData.thirdQ,
      forthQ: formData.forthQ,
      fifthQ: formData.fifthQ,
      sixthQ: formData.sixthQ,
      seventhQ: formData.seventhQ,
      eightthQ: formData.eightthQ,
      ninthQ: formData.ninthQ,
      tenthQ: formData.tenthQ,
      eleventhQ: formData.eleventhQ,
      twelveQ: formData.twelveQ,
      thirteenQ: formData.thirteenQ,
      forteenQ: formData.forteenQ,
      fifteenQ: formData.fifteenQ,
    };

    client
      .create(contact)
      .then(() => {
        navigate("/");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="app_agreement-overall-container">
      <div className="app_agreement-container">
        <div className="app_agreement-container-up-title">
          <h1>معلومات عن اللاعب لتأسيس الجدول المناسب</h1>
        </div>
        <div className="app_agreement-center-container">
          <form
            className="app_agreement-center-form-container"
            onSubmit={handleSubmit}
          >
            <div className="app_agreement-center-form-information">
              <h3 className="app_agreement-center-form-label">الإسم</h3>
              <input
                className="app_agreement-center-form-input"
                type="text"
                name="name"
                autoComplete="off"
                placeholder="الإسم ثلاثي أو رباعي"
                value={username}
                onChange={handleChangeInput}
                required={true}
              />
              <h3 className="app_agreement-center-form-label">السن</h3>
              <input
                className="app_agreement-center-form-input"
                type="number"
                name="age"
                autoComplete="off"
                placeholder="سن اللاعب"
                value={age}
                onChange={handleChangeInput}
                required={true}
              />
              <h3 className="app_agreement-center-form-label">العنوان</h3>
              <input
                className="app_agreement-center-form-input"
                type="address"
                name="town"
                autoComplete="off"
                placeholder="المدينة"
                value={username}
                onChange={handleChangeInput}
                required={true}
              />
              <h3 className="app_agreement-center-form-label">الهاتف</h3>
              <input
                className="app_agreement-center-form-input"
                type="text"
                name="whatsapp"
                autoComplete="off"
                placeholder="رقم واتساب"
                value={username}
                onChange={handleChangeInput}
                required={true}
              />
            </div>
            <hr />
            <div className="app_agreement-center-form-center-container">
              <div className="app_agreement-center-container-up-title">
                <h1>من فضلك قم بالإجابة عن الأسئلة التالية بعناية</h1>
              </div>
              <h3 className="app_agreement-center-form-label2">
                هل قام طبيبك بتشخيص إصابتك بمرض في القلب ؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="firstQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل سبق أن عانيت من الذبحة الصدرية أو الألم الحاد أو الضغط الشديد
                في صدرك نتيجة ممارسة الرياضة أو المشي أو أي نشاط بدني آخر مثل
                صعود السلالم؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="secondQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل تعاني من أي ألم حاد أو ضيق شديد في صدرك في درجات الحرارة
                الباردة؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="thirdQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل سبق لك أن تعرضت لنوبة قلبية حقيقية أو مشتبه بها، أو انسداد
                الشريان التاجي، أو احتشاء عضلة القلب، أو قصور الشريان التاجي، أو
                تجلط الدم؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="forthQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل سبق أن أصبت بالحمى الروماتيزمية؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="fifthQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل سبق لك أو تتناول أدوية أو تتبع نظامًا غذائيًا خاصًا لخفض نسبة
                الكوليسترول لديك؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="sixthQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل سبق لك تناول الديجيتال أو الكينين أو أي دواء آخر لقلبك؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="seventhQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل لديك حالة بدنية أو ضعف أو إعاقة، بما في ذلك مشكلة في المفاصل
                أو العضلات، والتي ينبغي أخذها في الاعتبار قبل البدء في برنامج
                اللياقة البدنية؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="eightthQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="إذا كانت الإجابة نعم برجاء كتابة التفاصيل"
                required={true}
              />
            </div>
            <hr />
            <div className="app_agreement-center-form-center-container">
              <div className="app_agreement-center-container-up-title">
                <h1>من فضلك قم بالإجابة على الأسئلة التالية</h1>
              </div>
              <h3 className="app_agreement-center-form-label2">
                ما هو هدف اللياقة الأساسي الخاص بك؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="ninthQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="مثال : زيادة الوزن / إنقاص وزن / ضخامة عضلية / لياقة / قوة / الخ"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                ما هو التاريخ الذي تريد أن تحقق فيه هدفك تقريبياً؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="date"
                name="tenthQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                كم مرة تمارس الرياضة حاليًا كل أسبوع؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="eleventhQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="مثال : 3 مرات إسبوعيا / 5 مرات إسبوعيا"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                ما هو الدافع الأساسي الخاص بك لتحقيق هدفك ؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="twelveQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="مثال : تحسين الصحة العامة"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                ما مدى جديتك في تحقيق هدفك ؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="number"
                name="thirteenQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="مثال : يرجى الاختيار من رقم 1 الى رقم 5 حيث ان رقم 1 غير متحمس ورقم 5 متحمس جداً"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                ما هي أكبر العوائق التي تحول دون نجاحك ؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="forteenQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="مثال : الدراسة / بعد مكان الصالة الرياضية عن مكان السكن"
                required={true}
              />
              <h3 className="app_agreement-center-form-label2">
                هل لديك أي شخص يدعمك حاليًا في السعي لتحقيق أهدافك ؟
              </h3>
              <input
                className="app_agreement-center-form-input2"
                type="text"
                name="fifteenQ"
                value={username}
                onChange={handleChangeInput}
                autoComplete="off"
                placeholder="مثال : نعم أبي / أمي / زوجتي / صديقي"
                required={true}
              />
            </div>
            <div>
              <hr />
              <div className="app_agreement-center-form-center-container">
                <div className="app_agreement-center-container-up-title">
                  <h1>
                    سياسة الاشتراك والشروط والأحكام للتدريب والتغذية الشخصية عبر
                    الإنترنت مع ك/ أحمد رحمو
                  </h1>
                </div>
              </div>
              <CustomizedAccordions />
            </div>
            <hr />
            <div className="app_agreement-submit-container">
              <button
                className="app_agreement-submit-button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                إرسال البيانات
              </button>
            </div>
          </form>
        </div>
        <div>.</div>
      </div>
    </div>
  );
};

export default Agreements;
