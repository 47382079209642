import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkoutTemplateAction,
  fetchWorkoutsAction,
  deleteWorkoutTemplateFromTrainerAction,
} from "../../../redux/slices/workout/workoutSlices";

import "./WorkoutTemp.scss";
import DateFormatter from "../../../utils/DateFormatter";

const formSchema = Yup.object({
  overallGoal: Yup.string().required("يرجى إدخال الهدف الأساسي من التدريب"),
  workoutFocus: Yup.string().required("يرجى إدخال تركيز التدريب على أي عضلة"),
});

const WorkoutTemp = () => {
  const dispatch = useDispatch();

  //select store data
  const workouts = useSelector((state) => state?.workout);
  const { fetchWorkout, isCreated, loading, appErr, serverErr } = workouts;

  //formik
  const formik = useFormik({
    initialValues: {
      overallGoal: "",
      workoutFocus: "",
      notes: "",
      imageLink: "",
      youtubeLink: "",
      warmUp: [
        {
          exerciseName: "",
          sets: "",
          reps: "",
          tempo: "",
          load: "",
          weight: "",
          rest: "",
          notes: "",
          imageLink: "",
          youtubeLink: "",
        },
      ],
      resistance: [
        {
          exerciseName: "",
          sets: "",
          reps: "",
          tempo: "",
          load: "",
          weight: "",
          rest: "",
          notes: "",
          imageLink: "",
          youtubeLink: "",
        },
      ],
      cardio: [
        {
          exerciseName: "",
          sets: "",
          reps: "",
          tempo: "",
          load: "",
          weight: "",
          rest: "",
          notes: "",
          imageLink: "",
          youtubeLink: "",
        },
      ],
    },

    onSubmit: (values) => {
      //dispath the action

      const data = {
        overallGoal: values?.overallGoal,
        workoutFocus: values?.workoutFocus,
        notes: values?.notes,
        imageLink: values?.imageLink,
        youtubeLink: values?.youtubeLink,
        workouts: {
          warmUp: values?.warmUp || [], // Include warmUp, resistance, and cardio arrays
          resistance: values?.resistance || [],
          cardio: values?.cardio || [],
        },
      };
      // data.workouts.warmUp = values?.warmUp || [];
      // data.workouts.resistance = values?.resistance || [];
      // data.workouts.cardio = values?.cardio || [];
      dispatch(createWorkoutTemplateAction(data));
      console.log(values);
    },

    validationSchema: formSchema,
  });

  useEffect(() => {
    dispatch(fetchWorkoutsAction(""));
  }, [dispatch]);

  const addExercise = (type) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      [type]: [
        ...prevValues[type],
        {
          exerciseName: "",
          sets: "",
          reps: "",
          tempo: "",
          load: "",
          notes: "",
          imageLink: "",
          youtubeLink: "",
        },
      ],
    }));
  };

  const removeExercise = (type, index) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      [type]: prevValues[type].filter((_, i) => i !== index),
    }));
  };
  return (
    <div className="app_workoutTemp-full-container">
      <div className="app_workoutTemp-full-up-container">
        <div className="app_workoutTemp-mini-up-container">
          <h1>يمكنك عمل قالب تدريبي جاهز</h1>
          {appErr || serverErr ? (
            <p className="mt-2 text-center text-lg text-red-600">
              {serverErr} {appErr}
            </p>
          ) : null}
        </div>
        <div className="app_workoutTemp-full-down-container">
          <form onSubmit={formik.handleSubmit}>
            <div className="app_workoutTemp-mini-down-left-container">
              <label>Overall Goal</label>
              <input
                value={formik?.values?.overallGoal}
                onChange={formik.handleChange("overallGoal")}
                onBlur={formik.handleBlur("overallGoal")}
                id="overallGoal"
                name="overallGoal"
                className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                type="text"
                placeholder="Over All Goals"
              />

              <div className="text-red-400 text-lg ml-6">
                {" "}
                {formik.touched.overallGoal && formik.errors.overallGoal}
              </div>
              <label>Workout Focus</label>
              <input
                value={formik.values.workoutFocus}
                onChange={formik.handleChange("workoutFocus")}
                onBlur={formik.handleBlur("workoutFocus")}
                id="workoutFocus"
                name="workoutFocus"
                className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                type="text"
                placeholder="Workout Focus"
              />

              <div className="text-red-400 text-lg ml-6">
                {formik.touched.workoutFocus && formik.errors.workoutFocus}
              </div>
              <label>Notes</label>
              <input
                value={formik.values.notes}
                onChange={formik.handleChange("notes")}
                onBlur={formik.handleBlur("notes")}
                id="notes"
                name="notes"
                className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                type="text"
                placeholder="Notes"
              />
              <label>Image Link</label>
              <input
                value={formik.values.imageLink}
                onChange={formik.handleChange("imageLink")}
                onBlur={formik.handleBlur("imageLink")}
                id="imageLink"
                name="imageLink"
                className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                type="text"
                placeholder="Image Link"
              />
              <label>Youtube Link</label>
              <input
                value={formik.values.youtubeLink}
                onChange={formik.handleChange("youtubeLink")}
                onBlur={formik.handleBlur("youtubeLink")}
                id="dayNotes"
                name="dayNotes"
                className=" pr-6 pl-4 py-4 font-bold placeholder-gray-300 focus:outline-none"
                type="text"
                placeholder="Youtube Link"
              />

              {loading ? (
                <button
                  type="submit"
                  disabled
                  className="w-full flex justify-center my-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Loading please wait...
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center my-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </button>
              )}
            </div>
            <div className="app_workoutTemp-full-down-right-container">
              <div className="app_workoutTemp-mini-down-right-container">
                {["warmUp", "resistance", "cardio"].map((type) => (
                  <div
                    key={type}
                    className="app_workoutTemp-mini-down-right-button"
                  >
                    <h2>{type}</h2>
                    {formik.values[type].map((exercise, index) => (
                      <div key={index} className="">
                        {/* Inputs for exercise details */}
                        <input
                          value={exercise.exerciseName}
                          placeholder="Exercise Name"
                          type="text"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].exerciseName`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.sets}
                          placeholder="Sets"
                          type="number"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].sets`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.reps}
                          placeholder="Reps"
                          type="number"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].reps`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.tempo}
                          placeholder="Tempo"
                          type="text"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].tempo`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.load}
                          placeholder="Load"
                          type="text"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].load`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.weight}
                          placeholder="Weight"
                          type="number"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].weight`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.rest}
                          placeholder="Rest"
                          type="text"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].rest`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.notes}
                          placeholder="Notes"
                          type="text"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].notes`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.imageLink}
                          placeholder="Image Link"
                          type="text"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].imageLink`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        <input
                          value={exercise.youtubeLink}
                          placeholder="Youtube Link"
                          type="text"
                          onChange={(e) =>
                            formik.setFieldValue(
                              `${type}[${index}].youtubeLink`,
                              e.target.value
                            )
                          }
                          // ... (other input fields)
                        />
                        {/* Remove exercise button */}

                        <button
                          type="button"
                          onClick={() => removeExercise(type, index)}
                          className="w-10 flex justify-center my-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          (-)
                        </button>
                      </div>
                    ))}
                    {/* Add exercise button */}
                    <button
                      type="button"
                      onClick={() => addExercise(type)}
                      className="w-10 flex justify-center my-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      (+)
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        {" "}
        {appErr || serverErr ? (
          <h1>
            {serverErr} {appErr}
          </h1>
        ) : fetchWorkout?.length <= 0 ? (
          <h1>There is no Workout Plans yet</h1>
        ) : (
          fetchWorkout?.map((workout) => (
            <div className="app_workout-bottom-item-container">
              <button
                onClick={() =>
                  dispatch(
                    deleteWorkoutTemplateFromTrainerAction({
                      workoutId: workout._id,
                    })
                  )
                }
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded inline-flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm-1-6a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 11-2 0v-1h-2a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                Delete
              </button>
              <h3 className="text-white">
                Overall Goal: {workout?.workoutTemplate?.overallGoal}
              </h3>
              <h3 className="text-white">
                Workout Focus: {workout?.workoutTemplate?.workoutFocus}
              </h3>
              <h3 className="text-white">
                Image Link: {workout?.workoutTemplate?.imageLink}
              </h3>

              <div className="flex space-x-1 text-sm text-black-500">
                <time className="text-white">
                  <DateFormatter date={workout?.workoutTemplate?.createdAt} />
                </time>
                <span aria-hidden="true">&middot;</span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default WorkoutTemp;
