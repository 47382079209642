import React, { useEffect, useState } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import logo from "../../../assets/image/Logo.png";
import "./ClientNavbar.scss";
import { logoutUserAction } from "../../../redux/slices/users/usersSlices";
import { userProfileAction } from "../../../redux/slices/users/usersSlices";
import { useDispatch, useSelector } from "react-redux";
const ClientNavbar = () => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  //User data from store
  const state = useSelector((state) => state.users);

  const { userAuth } = state;
  const userId = userAuth._id;
  //Fetch user Profile
  useEffect(() => {
    dispatch(userProfileAction());
  }, [dispatch]);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        <li className="app__flex app__navbar-links2 p-text">
          <Link className="app__navbar-links-btns" to="/find-mycoach">
            Coach
          </Link>
          <Link className="app__navbar-links-btns" to="/training-guideline">
            Training Guideline
          </Link>
          <Link className="app__navbar-links-btns" to="/training-workout">
            Workout
          </Link>
          <Link className="app__navbar-links-btns" to="/training-nutrition">
            NUTRITION
          </Link>
          <Link className="app__navbar-links-btns" to="/training-supplement">
            Supplement Recommendation
          </Link>
          <Link className="app__navbar-links-btns" to="/training-tools">
            Tools & Gadget
          </Link>
        </li>
      </ul>
      <div>
        {" "}
        <a href="/">
          <button
            className="mr-10 app__profile-resume-btn-1"
            onClick={() => dispatch(logoutUserAction())}
          >
            Logout
          </button>
        </a>
      </div>
      <div>
        <Link to={`/profile/${userId}`}>
          <img
            className=" h-12 w-full object-cover lg:h-12 rounded-full"
            src={userAuth?.profilePhoto}
            alt={userAuth?.firstName}
          />
        </Link>
      </div>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links-ul">
              <li>
                <Link
                  className="app__navbar-links-btns1"
                  to="/find-mycoach"
                  onClick={() => setToggle(false)}
                >
                  Coaches
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/training-guideline"
                  onClick={() => setToggle(false)}
                >
                  Training Guideline
                </Link>

                <Link
                  className="app__navbar-links-btns1"
                  to="/training-workout"
                  onClick={() => setToggle(false)}
                >
                  Workout
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/training-nutrition"
                  onClick={() => setToggle(false)}
                >
                  NUTRITION
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/training-supplement"
                  onClick={() => setToggle(false)}
                >
                  Supplement Recommendation
                </Link>
                <Link
                  className="app__navbar-links-btns1"
                  to="/training-tools"
                  onClick={() => setToggle(false)}
                >
                  Tools & Gadget
                </Link>

                <Link
                  className="app__navbar-links-btns1"
                  to="/"
                  onClick={() => {
                    setToggle(false);
                    dispatch(logoutUserAction());
                  }}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default ClientNavbar;
