import React, { useEffect } from "react";
import "./TrainingGuidLine.scss";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createGuideLineAction,
  fetchAllGuideLinesPlansWithTrainer,
  deleteGuidelineTemplateFromTrainerAction,
} from "../../../redux/slices/guideline/guidelineSlice";
import DateFormatter from "../../../utils/DateFormatter";
import { Navigate } from "react-router-dom";

const formSchema = Yup.object({
  templateName: Yup.string().required("Template Name is Required"),
  title: Yup.string().required("Title is Required"),
  description: Yup.string().required("Description is Required"),
});

const TrainingGuidLine = () => {
  const dispatch = useDispatch();

  //select store data
  const guideline = useSelector((state) => state?.guideline);
  const { fetchguideline, isCreated, loading, appErr, serverErr } = guideline;

  //formik
  const formik = useFormik({
    initialValues: {
      templateName: "",
      title: "",
      description: "",
    },

    onSubmit: (values) => {
      //dispatch the action

      const data = {
        templateName: values?.templateName,
        title: values?.title,
        description: values?.description,
      };

      dispatch(createGuideLineAction(data));

      //fetch posts
    },
    validationSchema: formSchema,
  });
  useEffect(() => {
    dispatch(fetchAllGuideLinesPlansWithTrainer(""));
  }, [dispatch]);

  if (isCreated) return <Navigate to="/training-guidline-templates" />;
  return (
    <div className="app_guideline-background-trainer-big-container">
      <div className="app_guideline-trainer-big-container">
        <div className="app_guideline-trainer-up-big-container">
          {" "}
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <h1>
              {serverErr} {appErr}
            </h1>
            <div>
              <label
                htmlFor="templatename"
                className="block text-sm font-medium text-gray-700 py-3 px-3"
              >
                Template Name
              </label>
              <div className="mt-1">
                {/* Title */}
                <input
                  value={formik.values.templateName}
                  onChange={formik.handleChange("templateName")}
                  onBlur={formik.handleBlur("templateName")}
                  id="templateName"
                  name="templateName"
                  type="templateName"
                  autoComplete="templateName"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* Err msg */}
              <div className="text-red-500 px-3" dir="rtl">
                {formik?.touched?.templateName && formik?.errors?.templateName}
              </div>
            </div>
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700 py-3 px-3"
              >
                Title
              </label>
              <div className="mt-1">
                {/* Title */}
                <input
                  value={formik.values.title}
                  onChange={formik.handleChange("title")}
                  onBlur={formik.handleBlur("title")}
                  id="title"
                  name="title"
                  type="title"
                  autoComplete="title"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {/* Err msg */}
              <div className="text-red-500 px-3" dir="rtl">
                {formik?.touched?.title && formik?.errors?.title}
              </div>
            </div>
            {/* Category input goes here */}

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 px-3"
              >
                Description
              </label>
              {/* Description */}
              <textarea
                value={formik.values.description}
                onChange={formik.handleChange("description")}
                onBlur={formik.handleBlur("description")}
                rows="5"
                cols="10"
                className="rounded-lg appearance-none block w-full py-3 px-3 text-base text-center leading-tight text-gray-600   border border-gray-200 focus:border-gray-500  focus:outline-none"
                type="text"
              ></textarea>
              {/* Err msg */}
              <div className="text-red-500 px-3" dir="rtl">
                {formik?.touched?.description && formik.errors?.description}
              </div>
            </div>
            <div>
              {/* Submit btn */}
              {loading ? (
                <button
                  disabled
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Loading please wait...
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="app_guidelnes-bottom-big-container">
          <div className="app_guidelnes-bottom-small-container">
            {appErr || serverErr ? (
              <h1>
                {serverErr} {appErr}
              </h1>
            ) : fetchguideline?.length <= 0 ? (
              <h1>There is no GuideLines added yet</h1>
            ) : (
              fetchguideline?.map((guideline) => (
                <div className="app_guidelnes-bottom-item-container">
                  <button
                    onClick={() =>
                      dispatch(
                        deleteGuidelineTemplateFromTrainerAction({
                          guidlineId: guideline._id,
                        })
                      )
                    }
                  >
                    Delete {/* or use an icon or any other indication */}
                  </button>
                  <h3>Template Name: {guideline?.templateName}</h3>

                  <h3>Title: {guideline?.title}</h3>
                  <h3>Description: {guideline?.description}</h3>

                  <div className="flex space-x-1 text-sm text-black-500">
                    <time>
                      <DateFormatter date={guideline?.createdAt} />
                    </time>
                    <span aria-hidden="true">&middot;</span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingGuidLine;
