import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  addGudelineToUserAction,
  removeGudelineFromUserAction,
} from "../../../redux/slices/users/usersSlices";
import { useDispatch, useSelector } from "react-redux";
import DateFormatter from "../../../utils/DateFormatter";
import { fetchAllGuideLinesPlansWithTrainer } from "../../../redux/slices/guideline/guidelineSlice";

const ClientProfilePageGuideLine = () => {
  // Initialize selectedSupplementId with an empty string initially
  const [selectedGuideLineId, setSelectedGuidelineId] = useState("");

  const handleGuidelineSelection = (event) => {
    setSelectedGuidelineId(event.target.value);
  };

  const { id } = useParams();

  function capitalizeWord(word) {
    if (typeof word === "string" && word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    // Return an empty string or handle the case when word is undefined or empty
    return ""; // You can return an empty string or any other value as needed
  }
  const dispatch = useDispatch();

  //User data from store
  const users = useSelector((state) => state?.users);
  const { guidelineDetail } = users;

  // Assuming you have a list of available supplements in your Redux state
  const guidelines = useSelector((state) => state?.guideline);
  const { fetchguideline } = guidelines;

  // Fetch supplement data when the component mounts
  useEffect(() => {
    dispatch(fetchAllGuideLinesPlansWithTrainer());
  }, [dispatch]);

  // Update selectedSupplementId when supplementCreated changes
  useEffect(() => {
    // Check if supplementCreated is not empty and update selectedSupplementId with the first supplement's ID
    if (fetchguideline?.length > 0 && !selectedGuideLineId) {
      setSelectedGuidelineId(fetchguideline[0]?.id || "");
    }
  }, [fetchguideline, selectedGuideLineId]);
  const handleAddSupplement = () => {
    if (selectedGuideLineId) {
      dispatch(
        addGudelineToUserAction({
          clientId: id,
          guidelineId: selectedGuideLineId,
        })
      );
    }
  };

  return (
    <div>
      <div className="flex justify-center place-items-start flex-wrap  md:mb-0">
        {/* All my Post */}
        <div className="w-full md:w-2/3 px-4 mb-4 md:mb-0 text-white">
          <h1 className="text-center text-xl border-gray-500 mb-2 border-b-2">
            GuideLines
          </h1>

          {/* Loo here */}
          {guidelineDetail?.guideline.length <= 0 ? (
            <h2 className="text-center text-xl"> No Guidelines Added Yet</h2>
          ) : (
            guidelineDetail?.guideline.map((guideline) => (
              <div className="flex justify-center align-middle flex-wrap  -mx-3 mt-3   lg:mb-6">
                <div className="w-full lg:w-3/4 px-3">
                  <button
                    onClick={() =>
                      dispatch(
                        removeGudelineFromUserAction({
                          clientId: id,
                          guidelineId: guideline?.id,
                        })
                      )
                    }
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    Remove
                  </button>
                  <h3 className="mb-1 text-2xl text-white font-bold font-heading">
                    {capitalizeWord(guideline?.title)}
                  </h3>

                  <p className="text-white ">{guideline?.description}</p>
                  <p className="m-3 text-black bg-white font-bold text-lg">
                    Created At: <DateFormatter date={guideline?.createdAt} />
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="flex justify-center align-middle items-center mb-4">
        <select
          className="p-2 border text-black border-gray-300 rounded-md mr-2"
          onChange={handleGuidelineSelection}
          value={selectedGuideLineId}
        >
          {/* Map over available supplements and create options */}
          {fetchguideline?.map((guideline) => (
            <option key={guideline.id} value={guideline.id}>
              {guideline.title}
            </option>
          ))}
        </select>
        <button
          onClick={handleAddSupplement}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add GuideLine
        </button>
      </div>
    </div>
  );
};
export default ClientProfilePageGuideLine;
